<template>
  <div class="grid justify-items-center h-5/6">
    <div class=""><img class="lg:h-64 xl:h-80" src="@/assets/landing_page.svg" /></div>
    <p v-if="landing" class="heading font-semibold text-5xl sm:text-3xl">{{ landing.title }}</p>
    <div v-if="privacy" class="grid justify-items-center border border-current relative w-4/6 mx-8 my-2">
      <div
        style="background-color: #ffb24d;font-family: Inter-SemiBold', 'Inter', sans-serif;"
        class="v4v h-32 text-center w-full relative text-lg cursor-pointer font-extralight p-8 text-2xl"
      >
        Privacy policy
      </div>

      <div class="m-4" style="color: var(--texte_grey_black_title, rgba(15, 15, 15, 0.69))">
        <p v-html="privacy.content"></p>
      </div>

      <div v-if="islog?.jwt !== ''" class="grid grid-cols-1 place-items-center w-full">
        <div class="flex w-3/6 justify-center space-x-8">
          <router-link class="my-10 p-2 w-2/6 rounded-full text-white text-center bg-gray-700" to="/">
            AVBRYT
          </router-link>
          <div class="my-10 p-2 w-2/6 bg-amber-500 rounded-full grid">
            <div class="place-self-center">
              <div
                class="focus:outline-none w-full place-self-center bg-amber-500 hover:cursor-pointer"
                @click="DPlogin(false)"
              >
                LOGG INN
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex w-3/6 justify-center space-x-8">
        <router-link class="my-10 p-2 w-2/6 rounded-full text-white text-center bg-gray-700" to="/dashboard">
          AVBRYT
        </router-link>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { useCMSStore } from '@/store/useCMSStore'
  import useDeviceService from '../../composition/useDevice'
  import { useAppStore } from '@/store/useAppStore'
  import { baseUrl } from '@/constants'

  export default defineComponent({
    name: 'PrivacyPolicy',
    components: {},
    setup() {
      const { getters: cmsGetters } = useCMSStore()
      const { getters: appGetters } = useAppStore()
      const { getters: deviceGetters } = useDeviceService()
      function DPlogin(testing = false, username = 'testuser') {
        localStorage.setItem('isConsented', 'true')
        let feideLoginUrl = `${baseUrl}/auth/dataporten/login`

        feideLoginUrl += `?device=webApp${testing ? `&testing=${username}` : ''}`
        window.location.href = feideLoginUrl
      }
      return {
        DPlogin,
        privacy: cmsGetters.privacy,
        landing: cmsGetters.landingPage,
        islogged: deviceGetters.deviceOnline.value,
        islog: appGetters.currentLocalUser.value,
      }
    },
  })
</script>

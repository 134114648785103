<template>
  <div id="app" class="fadeInOut h-screen relative" :style="fadeState">
    <div class="absolute top-0 right-0 text-xs flex flex-row">
      <div v-if="disableDelays" class="mr-2 text-red-600 text-xs"><!-- Delays & locks disabled --></div>
    </div>
    <transition class="" mode="out-in" name="fade">
      <FeedbackOverlay v-if="feedbackMessage" :message="feedbackMessage"></FeedbackOverlay>
    </transition>
    <Loader v-if="showLoader"></Loader>
    <router-view />
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import { useAppStore } from '../src/store/useAppStore'
  import { useRouter } from 'vue-router'
  import FeedbackOverlay from './components/FeedbackOverlay.vue'
  import Loader from '@/components/base/LoaderSpinner.vue'
  const router = useRouter()
  const appStore = useAppStore()
  appStore.actions.detectOldApp()
  const fadeState = computed(() => {
    return { opacity: appStore.getters.fade.value ? '0' : '1' }
  })

  const feedbackMessage = computed(() => appStore.getters.feedbackMessage.value)

  const showLoader = computed(() => {
    return appStore.getters.status.value.loading
  })

  const disableDelays = appStore.getters.disableDelays
  console.log(router.currentRoute.value.path)
  // If we arrived here from a page reload, make sure to set up again
  if (router.currentRoute.value.path !== '/') {
    router.push('/login')
  }
</script>
<style lang="postcss">
  .fadeInOut {
    opacity: 1;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }

  #nav {
    padding: 30px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }
</style>

<template>
  <div>
    <div v-if="!showGuidance" class="grid grid-rows-1 justify-items-center">
      <div class="lg:w-5/6 xl:w-3/6 bg-white rounded-lg -mt-6">
        <div class="flex justify-between">
          <h1 class="text-3xl fontLight mb-6 text-center w-full">{{ individualReflex?.title }}</h1>
          <span class="cursor-pointer text-xl" @click="closeDetails">X</span>
        </div>

        <div class="mb-8">
          <p class="text-base font" v-html="individualReflex?.content"></p>
        </div>

        <!-- div for image -->
        <div class="my-24">
          <img :src="individualReflexImage" alt="Individual Reflex Image" class="max-w-full h-auto rounded-lg" />
        </div>

        <div class="flex justify-between mt-12 mb-12">
          <SlButton
            class="buttonbackgroundColor h-8 mt-5 w-28 focus:outline-none btn_text text-white text-sm"
            :custom-width="'8rem'"
            @click="previousContent"
          >
            Tilbake
          </SlButton>
          <SlButton
            class="buttonbackgroundColor h-8 mt-5 w-28 focus:outline-none btn_text text-white text-sm"
            :custom-width="'8rem'"
            @click="nextContent"
          >
            Neste
          </SlButton>
        </div>
      </div>
    </div>
    <Guidanceitem v-else :mode="mode" @close-popup="closeDetails" @switch-component="handleSwitchComponent" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted, ref } from 'vue'
  import { useCMSStore } from '@/store/useCMSStore'
  import { useUserStore } from '@/store/useUserStore'
  import individualReflexImage from '@/assets/Refleksjonsverktøy1.jpg'
  import SlButton from '@/components/base/SlButton.vue'
  import Guidanceitem from '@/components/GuidanceItem.vue'

  export default defineComponent({
    name: 'IndividualReflex',
    components: {
      SlButton,
      Guidanceitem,
    },
    props: {
      mode: { type: String, required: true },
    },
    emits: ['close-popup', 'switch-component'],
    setup(props, { emit }) {
      const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
      const individualReflex = computed(() => cmsGetters.individualReflex.value)

      onMounted(() => {
        cmsActions.getIndividualReflexContents()
      })

      const showReflex = ref(false)

      const closeDetails = () => {
        emit('close-popup')
      }

      const handleSwitchComponent = (component: string) => {
        if (component === 'reflex') {
          showGuidance.value = false
        }
      }

      const previousContent = () => {
        emit('switch-component', 'learn')
      }

      const nextContent = () => {
        showGuidance.value = true
      }

      const showGuidance = ref(false)
      return {
        individualReflex,
        closeDetails,
        individualReflexImage,
        SlButton,
        previousContent,
        nextContent,
        handleSwitchComponent,
        showGuidance,
        Guidanceitem,
        showReflex,
      }
    },
  })
</script>

<style scoped>
  .fontLight {
    letter-spacing: 0px;
    font-weight: 300;
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }

  .font {
    letter-spacing: 0px;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
  }

  .btn_text {
    font-family: 'Inter-Medium', 'Inter', sans-serif;
  }

  .buttonbackgroundColor {
    background-color: var(--Canvas_Bluelight, #1a6899);
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
</style>

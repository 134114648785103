<template>
  <div class="grid grid-rows-1 justify-items-center">
    <div class="lg:w-5/6 xl:w-3/6 bg-white rounded-lg -mt-6">
      <div class="flex justify-between">
        <h1 class="text-3xl fontLight mb-6 text-center w-full">{{ observationTheoryContent?.title }}</h1>
        <span class="cursor-pointer text-xl" @click="closeDetails">X</span>
      </div>

      <div class="-mx-6 px-6 mb-8 py-4 border-l border-r border-white bg-gray-50">
        <p class="text-base mb-4 font" v-html="observationTheoryContent?.introduction"></p>
      </div>

      <div v-for="(tab, index) in observationTheoryContent?.tabs" :key="index" class="mb-8 -mx-6 px-6 py-4 bg-gray-50">
        <h2 class="text-xl font-medium mb-4 fontBold">{{ tab.heading }}</h2>
        <div class="relative">
          <div class="text-base font bg-gray-50" v-html="tab.content?.replace('[[image]]', '')"></div>
          <div v-if="tab.content?.includes('[[image]]')" class="relative">
            <div class="absolute top-0 right-0 h-[26rem] z-10 -mt-48 -mr-6">
              <img :src="theoryImage1" alt="Theory background" class="h-full w-full object-contain z-0" />
            </div>
          </div>
        </div>
      </div>

      <div class="relative -mt-20 mb-10 overflow-visible -mx-6">
        <div class="absolute inset-y-0 left-0 h-[26rem] -mt-24">
          <img :src="theoryImage2" alt="Theory background" class="h-full w-full object-contain z-0" />
        </div>
        <div class="relative z-10 py-16 px-6">
          <h1 class="text-3xl fontLight mt-10 mb-3 text-center">{{ dimensions?.title }}</h1>
        </div>
      </div>

      <!-- 7cs section -->
      <div v-if="dimensions">
        <div v-for="(tab, index) in dimensions?.tabs" :key="index" class="mb-4">
          <div
            @click="toggleTab(index)"
            class="flex justify-between items-center p-4 bg-white rounded-lg cursor-pointer hover:bg-gray-50 transition-colors shadow-sm border border-b-2"
          >
            <h2 class="text-xl font-medium">{{ tab.heading }}</h2>
            <span
              class="transform transition-transform duration-300"
              :class="activeTab.includes(index) ? 'rotate-180' : ''"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M7 10L12 15L17 10"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <transition name="collapse">
            <div
              v-show="activeTab.includes(index)"
              class="bg-white p-6 rounded-b-lg shadow-sm mt-1 border-t-0 border border-b-2"
            >
              <p class="text-base font" v-html="tab.content"></p>
            </div>
          </transition>
        </div>
      </div>

      <div class="mt-12 pt-6 border-t">
        <h2 class="text-xl font-medium mb-4 fontLight">Referanser</h2>
        <p class="text-base font" v-html="observationTheoryContent?.notes"></p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted, ref } from 'vue'
  import { useCMSStore } from '@/store/useCMSStore'
  import theoryImage2 from '@/assets/theoryimage2.png'
  import theoryImage1 from '@/assets/theoryimage1.png'

  export default defineComponent({
    name: 'ObservationDetails',
    props: {
      mode: { type: String, required: true },
    },
    emits: ['close-popup', 'switch-component'],
    setup(props, { emit }) {
      const { getters: cmsGetters, actions: cmsActions } = useCMSStore()

      const observationTheoryContent = computed(() => cmsGetters.observationTheory.value)
      const dimensions = computed(() => cmsGetters.dimensions.value)

      const showLearn = ref(false)
      onMounted(() => {
        cmsActions.getDimensionsContents()
        cmsActions.getObservationTheoryContents()
      })

      const showTheory = ref(false)
      const activeTab = ref<number[]>([])
      const toggleTab = (index: number) => {
        const currentIndex = activeTab.value.indexOf(index)
        if (currentIndex === -1) {
          activeTab.value.push(index)
        } else {
          activeTab.value.splice(currentIndex, 1)
        }
      }

      const closeDetails = () => {
        emit('close-popup')
      }

      const handleSwitchComponent = (component: string) => {
        if (component === 'theory') {
          showTheory.value = true
          showLearn.value = false
        }
      }

      return {
        observationTheoryContent,
        closeDetails,
        dimensions,
        activeTab,
        toggleTab,
        theoryImage2,
        theoryImage1,
        showTheory,
        showLearn,
        handleSwitchComponent,
      }
    },
  })
</script>
<style scoped>
  .fontLight {
    letter-spacing: 0px;
    font-weight: 300;
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }

  .font {
    letter-spacing: 0px;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
  }

  .collapse-enter-active,
  .collapse-leave-active {
    transition: all 0.3s ease-in-out;
    max-height: 300px;
    opacity: 1;
  }

  .collapse-enter-from,
  .collapse-leave-to {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }

  .dimension-content {
    background-color: white;
    border-radius: 0 0 0.5rem 0.5rem;
  }
</style>

<template>
  <div class="grid grid-rows-1 justify-items-center">
    <div class="lg:w-5/6 xl:w-3/6 bg-white rounded-lg -mt-6">
      <div class="flex justify-between">
        <h1 class="text-3xl fontLight mb-6 text-center w-full">{{ title.heading }}</h1>
        <span class="cursor-pointer text-xl" @click="close">X</span>
      </div>

      <div class="mb-8">
        <p class="text-base font text-center w-full">{{ title.subHeading }}</p>
      </div>

      <!-- Reflection Items -->
      <div v-for="(reflection, index) in Array.from(reflections.values())" :key="index">
        <div class="mb-4">
          <!-- Clickable Header -->
          <div
            @click="toggleSection(reflection)"
            class="flex items-center cursor-pointer border rounded-lg hover:bg-gray-50 shadow-sm border-b-2"
          >
            <div class="w-1/3 p-4 flex items-center justify-center rounded-l-lg" :style="getItemColor(index)">
              <img
                :class="[
                  'justify-self-end rounded-lg',
                  index === 3 ? 'w-3/6 h-28' : 'w-5/6',
                  index === 1 ? 'h-20 w-16 m-4' : 'h-28',
                ]"
                :src="getItemImage(index)"
              />
            </div>
            <h2 class="w-2/3 flex flex-col items-center text-center justify-center text-2xl pl-4 pr-4 font-medium">
              {{ reflection.header }}
            </h2>
            <span
              class="transform transition-transform duration-300"
              :class="expandedSections.includes(reflection) ? 'rotate-180' : ''"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M7 10L12 15L17 10"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>

          <!-- Expandable Content -->
          <transition name="expand">
            <div
              v-if="expandedSections.includes(reflection)"
              class="bg-white px-6 py-2 border rounded-b-lg shadow-sm border-b-4 mt-1 border-t-0"
            >
              <div class="mb-2">
                <p class="text-base font" v-html="reflection.subheader"></p>
              </div>

              <div
                v-if="mode == PROJECT_NAME.survey && reflection.index == '2'"
                class="border-b rounded-lg p-2 mb-4 opacity-40 -ml-6 -mr-6"
                style="width: calc(100% + 3rem)"
                :style="{
                  borderColor: getItemColor(index)?.match(/#[0-9A-Fa-f]{6}/)?.[0] || '#000000',
                  width: 'calc(100% + 3rem)',
                }"
              ></div>

              <!-- Guide Section -->
              <div
                v-if="
                  (reflection.index == '2' && mode == PROJECT_NAME.simulator) ||
                  (mode == PROJECT_NAME.guides && reflection.index == '3')
                "
                class="grid grid-cols-3 gap-4 mb-12"
              >
                <div
                  class="grid col-span-3 border-b rounded-lg p-2 opacity-40 -ml-6 -mr-6"
                  :style="{ borderColor: getItemColor(index)?.match(/#[0-9A-Fa-f]{6}/)?.[0] || '#000000' }"
                ></div>
                <div
                  v-for="(stage, stageIndex) in reflection.stages"
                  :key="stageIndex"
                  class="flex flex-col bg-white rounded-3xl pt-4"
                >
                  <div
                    :style="getColor(stageIndex)"
                    class="h-32 flex flex-col items-center justify-center rounded-t-3xl"
                  >
                    <p
                      class="block text-white text-base rounded-full bg-transparent mt-2 mb-2 px-2 py-auto border-2 border-white items-center justify-center"
                    >
                      {{ stageIndex + 1 }}
                    </p>
                    <div class="w-16 h-16 flex items-end justify-center">
                      <img class="object-contain max-w-full max-h-full" :src="getImage(stageIndex)" />
                    </div>
                  </div>
                  <div class="border rounded-b-3xl grid h-full" style="background-color: white">
                    <p class="text-xl mt-2 mx-2 font-medium text-center justify-center">{{ stage.stageItem }}</p>
                    <p
                      class="text-base font-light mb-2 leading-snug mx-2 mt-2 text-center"
                      v-html="stage.stageItemSubtitle"
                    ></p>
                  </div>
                </div>
              </div>

              <!-- Content -->
              <div class="mb-0">
                <p class="text-base font" v-html="reflection.content"></p>
              </div>

              <!-- Questions -->
              <div v-if="reflection.question?.length > 0" class="grid justify-items-center">
                <div
                  class="border-b rounded-lg p-2 mb-4 opacity-40 -ml-6 -mr-6"
                  style="width: calc(100% + 3rem)"
                  :style="{
                    borderColor: getItemColor(index)?.match(/#[0-9A-Fa-f]{6}/)?.[0] || '#000000',
                    width: 'calc(100% + 3rem)',
                  }"
                ></div>
                <div class="grid grid-cols-3 gap-4 mt-8">
                  <div v-for="(question, qIndex) in reflection.question" :key="qIndex" class="my-4">
                    <div class="relative rounded-lg text-white py-5 px-4 h-full" :style="getItemColor(index)">
                      <div
                        class="flex flex-row h-8 my-2 mx-4 absolute bulb lg:left-14 xl:left-12 -top-6 rounded-3xl max-w-24"
                        :style="getItemColor(index)"
                      >
                        <div
                          class="h-14 w-14 absolute -left-10 -top-3.5 flex items-center justify-center rounded-full"
                          :style="[getItemColor(index), 'border: 2px solid white']"
                        >
                          <img class="h-10" src="@/assets/lightball2.svg" />
                        </div>
                        <p class="h-2 px-12 py-1">
                          <span class="text-white text-sm text-center">{{ qIndex + 1 }}</span>
                        </p>
                      </div>
                      <p class="mt-5 text-center">{{ question.questionItem }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Exercises -->
              <div v-if="reflection.fillup?.length > 0" class="w-5/6 mx-auto">
                <p class="subheader text-xl font-medium leading-relaxed mb-4">Fullfør disse setningene skriftlig</p>
                <div class="grid grid-cols-1 gap-4">
                  <div v-for="(item, fIndex) in reflection.fillup" :key="fIndex">
                    <div class="flex space-x-4 bg-white rounded-lg p-4">
                      <div
                        class="h-14 w-14 flex-shrink-0 flex items-center justify-center rounded-lg"
                        :style="getItemColor(index)"
                      >
                        <img class="h-10" src="@/assets/lightball2.svg" />
                      </div>
                      <p class="text-black flex-grow">{{ item.fillupItem }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <SlButton
        class="buttonbackgroundColor h-8 mb-24 w-28 focus:outline-none btn_text text-white text-sm"
        :custom-width="'8rem'"
        @click="close"
      >
        Tilbake
      </SlButton>
    </div>
  </div>
</template>

<script lang="ts">
  import SlButton from '@/components/base/SlButton.vue'
  import { defineComponent, PropType, ref, computed } from 'vue'
  import { useCMSStore } from '@/store/useCMSStore'
  import { PROJECT_NAME } from '@/constants'

  export default defineComponent({
    name: 'ReflectionsList',
    components: { SlButton },
    props: {
      title: {
        type: Object as PropType<{ heading: string; subHeading: string }>,
        required: true,
      },
      mode: { type: String, required: true },
    },
    emits: ['close-popup'],
    setup(props, { emit }) {
      const { getters: cmsGetters } = useCMSStore()
      const reflections = computed(() => cmsGetters.reflections.value)
      const expandedSections = ref<any[]>([])

      const toggleSection = (reflection: any) => {
        const index = expandedSections.value.indexOf(reflection)
        if (index === -1) {
          expandedSections.value.push(reflection)
        } else {
          expandedSections.value.splice(index, 1)
        }
      }

      const getItemImage = (index: number) => {
        switch (index) {
          case 0:
            return '/images/lightball2.svg'
          case 1:
            return '/images/Peer.svg'

          case 2:
            return '/images/Mentor.svg'
          case 3:
            return '/images/Loudspeaker.svg'
          case 4:
            return '/images/notes.svg'
        }
      }

      const getImage = (index: number) => {
        switch (index) {
          case 0:
            return '/images/Peer.svg'
          case 1:
            return '/images/Timer.svg'
          case 2:
            return '/images/Mentor.svg'
          default:
            return ''
        }
      }

      const getItemColor = (index: number) => {
        if (props.mode === PROJECT_NAME.simulator || props.mode === PROJECT_NAME.videotool) {
          switch (index) {
            case 0:
              return 'background-color: #19447F;'
            case 1:
              return 'background-color: #1AC47BE8;'
            case 2:
              return 'background-color: #FF8A00;'
          }
        } else if (props.mode === PROJECT_NAME.survey) {
          switch (index) {
            case 0:
              return 'background-color: #1AC47BE8;'
            case 1:
              return 'background-color: #EF9CF0;'
            case 2:
              return 'background-color: #FED73D;'
          }
        } else if (props.mode === PROJECT_NAME.guides) {
          switch (index) {
            case 0:
              return 'background-color: #FED73DE8;'
            case 1:
              return 'background-color: #19447F;'
            case 2:
              return 'background-color: #EF9CF0;'
            case 3:
              return 'background-color: #FF8A00;'
            case 4:
              return 'background-color: #1AC47BE8;'
          }
        }
        return 'background-color: #144578;'
      }

      const getColor = (index: number) => {
        switch (index) {
          case 0:
            return 'background-color: #FF8A00;'
          case 1:
            return 'background-color: #1A94C4E8;'
          case 2:
            return 'background-color: #EF9CF0;'
          default:
            return ''
        }
      }

      function close() {
        emit('close-popup')
      }

      return {
        reflections,
        expandedSections,
        toggleSection,
        getImage,
        getColor,
        close,
        PROJECT_NAME,
        getItemImage,
        getItemColor,
      }
    },
  })
</script>

<style scoped>
  .expand-enter-active,
  .expand-leave-active {
    transition: all 0.3s ease-in-out;
    max-height: 1000px;
    opacity: 1;
  }

  .expand-enter-from,
  .expand-leave-to {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }

  .fontLight {
    letter-spacing: 0px;
    font-weight: 300;
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }

  .font {
    letter-spacing: 0px;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
  }

  .light {
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }

  .text {
    font-family: 'Inter', sans-serif;
  }

  .subheader {
    font-family: 'Inter-Medium', 'Inter', sans-serif;
  }

  .questionItem {
    background-color: #19447f;
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }

  .bulb {
    border: 2px solid white;
  }
</style>

<template>
  <div class="flex flex-row w-full h-24 text-white text-sm my-4 justify-around">
    <div class="">
      <a href="https://www.uv.uio.no"><img v-cache class="w-64 h-20" src="@/assets/icons/svg/uio.svg" /></a>
    </div>
    <div class="mt-4 flex flex-col">
      <div>
        <a
          class="hover:text-red-400"
          href="https://www.uv.uio.no/ils/forskning/prosjekter/verktoy-veiledning-nye-larere/"
        >
          Prosjektet Verktøy for veiledning
        </a>
        /
        <a
          class="hover:text-red-400"
          href="https://www.uv.uio.no/ils/forskning/prosjekter/verktoy-veiledning-viken/index.html"
        >
          Prosjektet Verktøy for veiledning med nyutdannede lærere
        </a>
      </div>
      <router-link style="" class="hover:text-red-400" to="/privacy">Personvernserklaring</router-link>
    </div>
    <div class="mt-4 flex flex-col">
      <p>Kontakt oss på</p>
      <a class="hover:text-red-400" href="mailto:ils-vfv@ils.uio.no">ils-vfv@ils.uio.no</a>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  export default defineComponent({
    name: 'FooterComponent',
    setup() {
      return {}
    },
  })
</script>

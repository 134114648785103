<template>
  <div>
    <div class="grid grid-rows-1 justify-items-center">
      <div class="lg:w-5/6 xl:w-3/6 bg-white rounded-lg -mt-6">
        <div class="flex justify-between">
          <h1 class="text-3xl fontLight mb-6 text-center w-full">{{ guidance?.title }}</h1>
          <span class="cursor-pointer text-xl" @click="closeDetails">X</span>
        </div>

        <div class="mb-8">
          <p class="text-base font" v-html="guidance?.content"></p>
        </div>

        <!-- div for image -->
        <div class="my-24">
          <img :src="guidanceImage" alt="Guidance Image" class="max-w-full h-auto rounded-lg" />
        </div>

        <div class="flex justify-between mt-12 mb-12">
          <SlButton
            class="buttonbackgroundColor h-8 mt-5 w-28 focus:outline-none btn_text text-white text-sm"
            :custom-width="'8rem'"
            @click="previousContent"
          >
            Tilbake
          </SlButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted, ref } from 'vue'
  import { useCMSStore } from '@/store/useCMSStore'
  import guidanceImage from '@/assets/Refleksjonsverktøy1.jpg'
  import SlButton from '@/components/base/SlButton.vue'

  export default defineComponent({
    name: 'GuidanceItem',
    components: {
      SlButton,
    },
    props: {
      mode: { type: String, required: true },
    },
    emits: ['close-popup', 'switch-component'],
    setup(props, { emit }) {
      const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
      const guidance = computed(() => cmsGetters.guidance.value)

      const showReflex = ref(false)

      onMounted(() => {
        cmsActions.getGuidanceContents()
      })

      const closeDetails = () => {
        emit('close-popup')
      }

      const handleSwitchComponent = (component: string) => {
        if (component === 'guidance') {
          showReflex.value = false
        }
      }

      const previousContent = () => {
        emit('switch-component', 'reflex')
      }

      const showGuidance = ref(false)

      return {
        guidance,
        closeDetails,
        guidanceImage,
        SlButton,
        previousContent,
        handleSwitchComponent,
        showGuidance,
      }
    },
  })
</script>

<style scoped>
  .fontLight {
    letter-spacing: 0px;
    font-weight: 300;
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }

  .font {
    letter-spacing: 0px;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
  }

  .btn_text {
    font-family: 'Inter-Medium', 'Inter', sans-serif;
  }

  .buttonbackgroundColor {
    background-color: var(--Canvas_Bluelight, #1a6899);
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
</style>

<template>
  <div class="mb-4 bg-white overflow-y-scroll">
    <div class="mx-24 pb-2">
      <div class="flex justify-between items-center mx-12 py-4">
        <div class="flex-none">
          <div class="v4v relative text-lg flex flex-row cursor-pointer font-extralight hover:font-semibold">
            <div class="text-lg italic font-bold">Verktøy for veiledning</div>
          </div>
        </div>

        <div class="flex-none">
          <div
            style="border: 2px solid #d8dde3; box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1)"
            class="cursor-pointer rounded-lg h-11 w-11 flex items-center justify-center bg-white"
            @click="showDetails = !showDetails"
          >
            {{ thumbnail }}
          </div>
        </div>
      </div>

      <!-- Horizontal Rule with Shadow -->
      <div class="w-screen relative left-[50%] right-[50%] -ml-[50vw] -mr-[50vw]">
        <div class="border-b border-gray-200" style="box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1)"></div>
      </div>

      <!-- Dropdown menu -->
      <div
        :class="!showDetails ? 'hidden' : ''"
        class="absolute top-20 right-12 cursor-pointer mb-8 h-5 text-sm rounded-lg"
      >
        <div
          class="cursor-pointer text-sm mx-1 left-0 top-0 px-2 rounded-lg hover:bg-sky-600 hover:text-white"
          @click="logout"
        >
          Logg ut
        </div>
      </div>
    </div>
    <div class="frame justify-self-center w-full">
      <img src="@/assets/Top.svg" alt="Frame background" class="h-full w-full object-cover" />
    </div>
    <div class="flex flex-col justify-center items-center">
      <div class="heading text-center w-5/6 text-5xl sm:text-3xl my-8">
        Velkommen til å ta i bruk våre verktøy for veiledning
      </div>
      <div class="subHeader text-base font-medium w-2/6 my-5 text-center">
        Verktøy for veiledning består av ulike verktøy organisert i flere pakker. Velg hvilken verktøypakke du vil jobbe
        med:
      </div>
      <div class="content-center grid grid-rows-3 h-4/12 grid-flow-col gap-3 my-4">
        <!-- First row: Beslutningssimulator and Observasjonsverktøy -->
        <div class="col-span-2 grid grid-cols-2 gap-3">
          <div v-for="tool in ['simulator', 'observationtool']" :key="tool" class="">
            <div
              class="border border-current rounded-lg flex flex-col justify-between space-y-2"
              :class="[simulatorInfo[tool], 'h-full w-96']"
            >
              <div
                class="w-full rounded-tl-lg rounded-tr-lg flex justify-center"
                :style="`${simulatorInfo[tool].color}`"
                :class="'h-24'"
              >
                <div>
                  <img
                    :class="[
                      tool === 'simulator' ? 'h-16 w-16 mt-8' : '',
                      tool === 'survey' ? 'h-24 w-24 mt-6' : '',
                      tool === 'observationtool' ? 'h-16 w-16 mt-8' : '',
                      tool === 'guides' ? 'h-38 w-38 -mt-6' : '',
                    ]"
                    :src="`${simulatorInfo[tool].imgsrc}`"
                  />
                </div>
              </div>
              <div class="inline-block text text-base font-light mx-12">
                {{ simulatorInfo[tool].text }}
              </div>
              <div class="flex justify-center">
                <SlButton
                  class="relative my-4 focus:outline-none bg-blue-600 text-white justify-self-end"
                  :custom-width="'8rem'"
                  :text-class="'buttonText'"
                  @click="selectTool(tool)"
                >
                  {{ simulatorInfo[tool].title }}
                </SlButton>
              </div>
            </div>
          </div>
        </div>

        <!-- Second row: Survey and Guides -->
        <div class="col-span-2 grid grid-cols-2 gap-3">
          <div v-for="tool in ['survey', 'guides']" :key="tool" class="">
            <div
              class="border border-current rounded-lg flex flex-col justify-between space-y-2"
              :class="[simulatorInfo[tool], 'h-full w-96']"
            >
              <div
                class="w-full rounded-tl-lg rounded-tr-lg flex justify-center"
                :style="`${simulatorInfo[tool].color}`"
                :class="'h-24'"
              >
                <div>
                  <img
                    :class="[
                      tool === 'simulator' ? 'h-16 w-16 mt-8' : '',
                      tool === 'survey' ? 'h-24 w-24 mt-6' : '',
                      tool === 'observationtool' ? 'h-16 w-16 mt-8' : '',
                      tool === 'guides' ? 'h-38 w-38 -mt-6' : '',
                    ]"
                    :src="`${simulatorInfo[tool].imgsrc}`"
                  />
                </div>
              </div>
              <div class="inline-block text text-base font-light mx-12">
                {{ simulatorInfo[tool].text }}
              </div>
              <div class="flex justify-center">
                <SlButton
                  class="relative my-4 focus:outline-none bg-blue-600 text-white justify-self-end"
                  :custom-width="'8rem'"
                  :text-class="'buttonText'"
                  @click="selectTool(tool)"
                >
                  {{ simulatorInfo[tool].title }}
                </SlButton>
              </div>
            </div>
          </div>
        </div>

        <!-- Third row: Video tool -->
        <div class="col-span-2 flex justify-center mt-3">
          <div
            class="border border-current rounded-lg flex flex-col justify-between"
            :class="[simulatorInfo.videotool]"
            style="width: 49rem; height: 16rem"
          >
            <div
              class="w-full rounded-tl-lg rounded-tr-lg flex justify-center"
              :style="`${simulatorInfo.videotool.color}`"
              style="height: 5rem"
            >
              <div>
                <img class="h-12 mt-6" :src="`${simulatorInfo.videotool.imgsrc}`" />
              </div>
            </div>

            <div class="inline-block text text-base font-light mx-12 my-4">
              {{ simulatorInfo.videotool.text }}
            </div>

            <div class="flex justify-center mb-6">
              <SlButton
                class="relative focus:outline-none bg-blue-600 text-white justify-self-end"
                :custom-width="'8rem'"
                :text-class="'buttonText'"
                @click="selectTool(PROJECT_NAME.videotool)"
              >
                {{ simulatorInfo.videotool.title }}
              </SlButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent class="w-full footerBg mt-8 -mb-8"></FooterComponent>
  </div>
</template>
<script lang="ts">
  import { defineComponent, ref, onMounted, computed } from 'vue'
  import SlButton from '@/components/base/SlButton.vue'
  // import camera from '@/assets/camera-movie-light.svg'
  import camera from '@/assets/camera-updated.svg'
  import { XHR_REQUEST_TYPE } from '@/types/main'
  import { apiRequest } from '@/api/apiRequest'
  import { useAppStore } from '@/store/useAppStore'
  import FooterComponent from '@/components/FooterComponent.vue'
  import { useCMSStore } from '@/store/useCMSStore'
  import useDeviceService from '@/composition/useDevice'
  import { PROJECT_NAME } from '@/constants'
  import router from '@/router'
  import lightball from '@/assets/lightball2.svg'
  import Peer from '@/assets/Peer.svg'
  import Mentor from '@/assets/Mentor.svg'
  import Top from '@/assets/Top.svg'
  import graduation from '@/assets/graduation.svg'
  import { useUserStore } from '@/store/useUserStore'
  interface TOOLINFO {
    title: string
    imgsrc: string
    color: string
    text: string
  }
  const { getters: appGetters, actions: appActions } = useAppStore()
  const { getters: userGetters, actions: userActions } = useUserStore()
  const { actions: deviceActions } = useDeviceService()
  const { actions: cmsActions } = useCMSStore()

  export default defineComponent({
    name: 'DashboardView',
    components: { SlButton, FooterComponent },
    setup() {
      const selectLTI = (mode: string) => {
        console.log(mode)
      }
      const selectedTool = appGetters.selectedToolMode
      const rememberMe = ref(false)
      const simulatorInfo: Record<string, TOOLINFO> = {
        simulator: {
          title: 'Beslutningssimulator',
          imgsrc: lightball,
          color: 'background-color:#ffb24d',
          text: 'Innta rollen som lærer i 10A og ta valg i situasjonene som oppstår! Resultatet knytter valgene til teori om ulike tilnærminger til lærerrollen. Her finner du også guider for veiledning og selvrefleksjon, laget for å bidra til refleksjon om lærerrolle.',
        },
        survey: {
          title: 'Respons fra elever og egenvurdering',
          imgsrc: Peer,

          color: 'background-color:var(--Canvas_Bluelight, #1a6899)',
          text: 'Hva tenker elevene om undervisningen de mottar? Og hva tenker den som underviser? Med disse verktøyene innhentes og analyseres respons som utforskes i selvrefleksjon og veiledning.',
        },
        observationtool: {
          title: 'Observasjonsverktøy',
          imgsrc: Mentor,
          color: 'background-color: #EF9CF0',
          text: 'Hvordan få mest mulig ut av observasjon av læreres arbeid? Her ligger verktøy utviklet for å planlegge læringsøkter, og for å holde felles fokus i observasjon og i observasjons-basert veiledning',
        },
        guides: {
          title: 'Nyutdannede som ressurs',
          imgsrc: graduation,
          color: 'background-color:#00cb8d',
          text: 'En nyutdannet er en ressurs for skolen sin! Men hva er de best på, og hvordan kan kompetansen deres komme hele skolen til gode? Her finner ledere, veiledere og nyutdannede verktøy som kan fremme hensiktsmessige strukturer for å ivareta nyutdannede på egen skole',
        },
        videotool: {
          title: 'Videoverktøy',
          imgsrc: camera,
          color: 'background-color: #F77D6D',
          text: 'Å filme egen praksis kan bidra til utviklende refleksjon og diskusjoner om lærerrollen! Her finner du verktøy for å filme, bearbeide, analysere og diskutere lærerpraksis basert på film.',
        },
      }

      onMounted(() => {
        cmsActions.getPrivacyContents()
      })
      const logout = () => {
        const completeLogout = () => {
          appActions.logout(rememberMe.value)

          router.push('/')
        }
        apiRequest({
          route: '/auth/logout',
          method: XHR_REQUEST_TYPE.GET,
          credentials: true,
        })
          .then(() => {
            completeLogout()
          })
          .catch((error) => {
            // Exchange was not accepted, clear the token and redirect to login page
            console.log(error)
            completeLogout()
          })
      }
      const selectTool = (index: string) => {
        appActions.setSelectTool(index).then(() => {
          router.push('/tool')
        })
      }
      deviceActions.saveMediaCache()

      const showDetails = ref(false)

      return {
        //methods
        // getImage,
        selectLTI,
        selectedTool,
        simulatorInfo,
        Top,
        logout,
        selectTool,
        PROJECT_NAME,
        showDetails,
        thumbnail: userGetters.selectedUser.value.profile.fullName
          ? userGetters.selectedUser.value.profile.fullName
              ?.match(/(\b\S)?/g)
              ?.join('')
              ?.toUpperCase()
          : '',
      }
    },
  })
</script>
<style>
  .footerBg {
    background-color: #4e4d4b;
  }
  .heading {
    font-family: 'Inter-SemiBold', 'Inter', sans-serif;
    color: var(--texte_grey_black_title, rgba(15, 15, 15, 0.69));
  }
  .subHeader {
    font-family: 'Inter-Medium', 'Inter', sans-serif;
    color: var(--texte_grey_black, #535050);
  }
  .buttonText {
    font-family: 'Inter', sans-serif;
    color: #ffffff;
  }

  .button {
    background-color: var(--token-f2b667fb-deef-4e64-9211-374fa3f73688, #246bfd);
    border-radius: 46px;
  }
  .frame {
    background-image: url('../../assets/Top.svg');
    background-repeat: no-repeat;
    background-size: contain;

    height: 656px;
  }
  .text {
    font-weight: 300;
    font-family: 'Inter-Light', 'Inter', sans-serif;
    color: var(--texte_grey_black_title, rgba(15, 15, 15, 0.69));

    text-align: center;
  }
</style>

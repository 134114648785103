interface AnswerOptions {
  answerOptionId: number
  correct: boolean
  externalAnswerOptionId: string
  preselected: boolean
  sequence: number
  text: string
}

interface Answer {
  answerId: number
  answerOptions: Array<AnswerOptions>
  externalQuestionId: string
  questionId: number
  textAnswer?: string
}

interface Question {
  description: string
  elementType: string
  externalQuestionId?: string
  questionId: number
  sequence: number
  text: string
}

interface Form {
  title?: string
  elementId: number
  description?: string
  elementType: string
  questions: Array<Question>
  sequence: number
}

export interface ReviewForm {
  title: string
  elements: Array<Form>
  answers: Array<Array<Answer>>
  questionTitles?: { [key: string]: string }
}

export interface Nettskjema {
  studentsReview: ReviewForm
  teachersReview: ReviewForm
}

// /* Classes */
// class QuestionReview {
//   questionText: string
//   average: number

//   constructor(spec: Form) {
//     this.questionText = spec.title || ''
//     spec.questions.forEach((q) => {
//       console.log(q)
//     })
//     this.average = 0
//   }
// }

// class QuestionMapEntry {
//   title: string
//   questionIds: Array<number>

//   constructor(spec: Form) {
//     this.title = spec.title || ''
//     this.questionIds = spec.questions.map((elem) => elem.questionId)
//   }
// }

enum ElementType {
  MATRIX_RADIO = 'MATRIX_RADIO',
}

export class Reviews {
  title: string
  review: Map<string, number>

  constructor(spec: ReviewForm) {
    this.title = spec.title
    this.review = new Map()

    if (!spec.answers || !Array.isArray(spec.answers)) {
      console.warn('No answers provided:', spec)
      return
    }

    // Create a map of question IDs to titles
    const questionTitles = new Map<string, string>()
    spec.elements.forEach((element) => {
      element.questions.forEach((question) => {
        if (question.externalQuestionId) {
          questionTitles.set(question.externalQuestionId, question.text)
        }
      })
    })

    // Group answers by questionId
    const answerGroups = new Map<string, number[]>()

    spec.answers.forEach((submission: Answer[]) => {
      submission.forEach((answer: Answer) => {
        if (answer.externalQuestionId === 'code' || !answer.answerOptions?.[0]) {
          return
        }

        const questionId = answer.externalQuestionId
        const value = Number(answer.answerOptions[0].text)

        if (!answerGroups.has(questionId)) {
          answerGroups.set(questionId, [])
        }
        if (value > 0) {
          answerGroups.get(questionId)?.push(value)
        }
      })
    })

    // Calculate averages using question titles
    answerGroups.forEach((values, questionId) => {
      if (values.length > 0) {
        const average = values.reduce((sum, val) => sum + val, 0) / values.length
        const title = spec.questionTitles?.[questionId] || questionId
        this.review.set(title, average)
      }
    })

    console.log('Processed review data:', {
      title: this.title,
      questionCount: this.review.size,
      questions: Array.from(this.review.entries()),
    })
  }
}

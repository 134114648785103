<template>
  <div class="relative flex flex-col h-screen justify-between bg-white overflow-y-scroll">
    <transition mode="out-in" name="fade">
      <div key="landing-main" class="flex flex-col items-center h-screen">
        <div class=""><img class="lg:h-64 xl:h-80" src="@/assets/landing_page.svg" /></div>
        <p v-if="landing" class="heading font-semibold text-5xl sm:text-3xl mb-2">{{ landing.title }}</p>
        <p v-if="landing" class="subtitle w-5/6 text-xl sm:w-4/6 text-xl text-center">{{ landing.subtitle }}</p>
        <p v-if="landing" class="subtitle w-5/6 text-xl sm:w-4/6 text-xl text-center my-2">
          {{ landing.secondSubtitle }}
        </p>
        <div
          style="height: 700%"
          class="relative w-2/6 m-8 border border-current rounded-lg"
          :class="privacyShow === false ? '' : 'hidden '"
        >
          <div
            class="relative h-32 rounded-tl-lg rounded-tr-lg pt-12 flex flex-row justify-center space-x-3"
            style="background-color: #ffb24d"
          >
            <img class="" src="@/assets/lightball2.svg" />
            <img class="" src="@/assets/Mentor.svg" />
            <img class="" src="@/assets/clock.svg" />
          </div>
          <div class="h-96 flex flex-col justify-center">
            <div class="text-3xl text-black text-center p-8 sm:text-xl">Logg in med Feide porten her</div>
            <div class="text-center w-4/6 break-normal self-center" style="color: #8a8a8a">
              Feide-porten er en felles innloggingsløsning til offentlige tjenester på internett
            </div>
            <div class="flex flex justify-center mt-12">
              <div>
                <SlButton background-class=" bg-blue-600" @click="DPlogin(false)">Logg inn</SlButton>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col items-center">
          <PrivacyPolicy
            class="border border-current"
            :class="
              privacyShow === true
                ? 'flex-shrink-0 backdrop-brightness-60 backdrop-filter backdrop-blur-sm w-full'
                : 'backdrop-blur-md backdrop-brightness-150 md:backdrop-filter-none hidden '
            "
            @close-policy="closePolicy()"
          ></PrivacyPolicy>
        </div>

        <!-- <p class="absolute bottom-0 right-0 text-xs m-2">v{{ appVersion }}</p> -->
        <FooterComponent class="w-full mt-32 footerBg"></FooterComponent>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, Ref, onBeforeUnmount, onMounted } from 'vue'

  import SlButton from '@/components/base/SlButton.vue'

  import { baseUrl, appVersion } from '../../constants'
  import { useAppStore } from '../../store/useAppStore'
  import useDeviceService from '../../composition/useDevice'
  import { LocalUser } from '../../types/main'
  import PrivacyPolicy from '@/components/PrivacyPolicy.vue'
  import moment from 'moment'
  import { useCMSStore } from '@/store/useCMSStore'
  import FooterComponent from '@/components/FooterComponent.vue'
  const jwtExpiryConstant = import.meta.env.VITE_JWT_EXPIRY || '0'
  const jwtExpiry = Number.parseInt(jwtExpiryConstant as string)

  interface LocalUserWithExpiry extends LocalUser {
    expiresIn: Ref<string>
    valid: Ref<boolean>
  }

  export default defineComponent({
    name: 'LoginPage',
    components: {
      SlButton,
      // AnswerInput,
      PrivacyPolicy,
      FooterComponent,
    },
    setup() {
      const { getters: appGetters, actions: appActions } = useAppStore()
      const { getters: deviceGetters } = useDeviceService()
      const { getters: cmsGetters, actions: cmsActions } = useCMSStore()

      const mode = ref('login')
      const pinCode = ref('')
      const privacyShow = ref(false)
      const showTestLogin = ref(false)
      const selectedUser = ref()
      const localUsers = Object.values(appGetters.persistedLocalUsers.value)
      const localUsersWithExpiry: LocalUserWithExpiry[] = localUsers.map((lu) => ({
        ...lu,
        expiresIn: ref(''),
        valid: ref(false),
      }))

      function momentFormat(date: Date) {
        return moment(date).format('ddd, hA')
      }
      onMounted(() => {
        cmsActions.getPrivacyContents()
      })
      // onBeforeMount(() => {
      //   cmsActions.getLandingPageContents()
      // })
      showTestLogin.value = false

      function DPlogin(testing = false, username = 'testuser') {
        const consented = localStorage.getItem('isConsented')
        if (consented && consented == 'true') {
          let feideLoginUrl = `${baseUrl}/auth/dataporten/login`
          feideLoginUrl += `?device=webApp${testing ? `&testing=${username}` : ''}`
          window.location.href = feideLoginUrl
        } else {
          privacyShow.value = !privacyShow.value
        }
      }
      // This logs in the user without going through the FIEDE process, so long as they have a valid token
      function localUserLogin() {
        if (
          (selectedUser.value.pin === pinCode.value && deviceGetters.deviceOnline) ||
          process.env.NODE_ENV == 'development'
        ) {
          selectedUser.value.lastLogin = new Date()
          appActions.setCurrentLocalUser(selectedUser.value)
          appActions.tokenLogin().then((success: boolean) => {
            // if not success, go back to login page
            if (!success) {
              mode.value = 'login'
            }
          })
        }
      }

      function closePolicy() {
        console.log('Emits to close ')
        privacyShow.value = false
      }

      function selectUser(u: LocalUser) {
        localUsers.forEach((u) => (u.selected = false))
        u.selected = true
        selectedUser.value = u
        // mode.value = 'pp'
      }

      const calculateCountdown = () => {
        const dateNow = new Date()
        localUsersWithExpiry.forEach((lu: LocalUserWithExpiry) => {
          const countdown = jwtExpiry - moment(dateNow).diff(lu.lastLogin, 'seconds')
          lu.expiresIn.value = moment.duration(countdown, 'seconds').humanize()
          lu.valid.value = countdown > 0
        })
      }
      calculateCountdown()
      const countDownInterval = setInterval(() => {
        calculateCountdown()
      }, 10000)
      onBeforeUnmount(() => {
        clearInterval(countDownInterval)
      })

      const filteredLocalUsersWithExpiry = computed(() => localUsersWithExpiry.filter((u) => u.valid.value))

      return {
        status: appGetters.status,
        filteredLocalUsersWithExpiry,
        deviceReady: deviceGetters.deviceOnline,

        appVersion,
        momentFormat,
        showTestLogin,

        selectUser,
        pinCode,
        DPlogin,

        localUserLogin,

        selectedUser,
        privacyShow,
        landing: cmsGetters.landingPage,
        closePolicy,
      }
    },
  })
</script>

<!-- <i18n>
{
 "no": {
 },
 "en": {
 }
}
</i18n> -->

<style scoped>
  .heading {
    font-family: 'Inter-SemiBold', 'Inter', sans-serif;
    color: var(--texte_grey_black_title, rgba(15, 15, 15, 0.69));
  }
  .subtitle {
    font-family: 'Inter-Medium', 'Inter', sans-serif;
    color: var(--texte_grey_black_title, rgba(15, 15, 15, 0.69));
  }
  .login {
    font-family: 'Inter', sans-serif;
    color: #000000;
    font-size: 38px;
    letter-spacing: 0px;
    line-height: 1.4;
    text-align: left;
  }
  .footerBg {
    background-color: #4e4d4b;
  }
</style>

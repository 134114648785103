import { cmsRequest } from '@/api/cmsService'
import { PROJECT_NAME, BLCToSLC, BrowserLanguageCodes } from '@/constants'
import { ref, Ref, computed, ComputedRef } from 'vue'
import { CmsGQLQuery } from '@/types/main'
import {
  Observation,
  ObservationData,
  ObservationTheoryData,
  ObservationTheory,
  ObservationDetailData,
  ObservationDetail,
  DimensionsData,
  Dimensions,
  ObservationPlanData,
  ObservationPlan,
  ObservationFormData,
  ObservationForm,
  ObservationLearnData,
  ObservationLearn,
  IndividualReflexData,
  IndividualReflex,
  GuidanceData,
  Guidance,
} from '@/types/observationModel'
// import { useProjectStore } from '@/store/useProjectStore'
import {
  Item,
  ItemData,
  LeadershipData,
  Leadership,
  Reflection,
  ReflectionData,
  DashboardData,
  Dashboard,
  PrivacyData,
  Privacy,
  LandingPage,
  LandingPageData,
} from '@/types/itemModel'

// Query strings for each Project
import itemQuery from '@/store/graphql/item.gql'
import leadershipQuery from '@/store/graphql/leadership.gql'
import reflectionQuery from '@/store/graphql/reflectionTool.gql'
import dashboardQuery from '@/store/graphql/dashboard.gql'
import privacyQuery from '@/store/graphql/privacy.gql'
import LandingPageQuery from '@/store/graphql/landingPage.gql'
import ObservationQuery from '@/store/graphql/ObservationTool.gql'
import ObservationTheoryQuery from '@/store/graphql/observationTeori.gql'
import ObservationDetailQuery from '@/store/graphql/observationdetail.gql'
import DimensionsQuery from '@/store/graphql/dimensions.gql'
import ObservationPlanQuery from '@/store/graphql/observationplanlegging.gql'
import ObservationFormQuery from '@/store/graphql/observationform.gql'
import ObservationLearnQuery from '@/store/graphql/observationlearn.gql'
import IndividualReflexQuery from '@/store/graphql/individualreflex.gql'
import GuidanceQuery from '@/store/graphql/guidance.gql'
// import videoReflectionQuery from '../graphql/videoReflection.gql'
import { useAppStore } from '@/store/useAppStore'
// import { useUserStore } from '@/store/useUserStore'
// const projectStore = useProjectStore()
const { getters: appGetters, actions: appActions } = useAppStore()
const NumberOfRecords = 150
// State
interface State {
  project: PROJECT_NAME

  selectedItem: Item | undefined
  items: Map<string, Item>
  leaderships: Map<string, Leadership>
  reflections: Map<string, Reflection>
  dashboard: Dashboard | undefined
  privacy: Privacy | undefined
  landingPage: LandingPage | undefined
  observation: Observation | undefined
  observationTheory?: ObservationTheory | undefined
  observationDetail?: ObservationDetail | undefined
  dimensions?: Dimensions | undefined
  observationPlan?: ObservationPlan | undefined
  observationForm?: ObservationForm | undefined
  observationLearn?: ObservationLearn | undefined
  individualReflex?: IndividualReflex | undefined
  guidance?: Guidance | undefined
}

const state: Ref<State> = ref({
  project: PROJECT_NAME.simulator,
  selectedItem: undefined,
  items: new Map(),
  leaderships: new Map(),
  reflections: new Map(),
  dashboard: undefined,
  privacy: undefined,
  landingPage: undefined,
  observation: undefined,
  observationDetail: undefined,
  observationTheory: undefined,
  dimensions: undefined,
  observationPlan: undefined,
  observationForm: undefined,
  observationLearn: undefined,
  individualReflex: undefined,
  guidance: undefined,
})
// Internal Functions
async function fetchItem(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  // const project = projectStore.getters.selectedProject.value
  const query = itemQuery.loc.source.body as string

  // const squidexLanguage = BLCToSLC[language]
  const variables = {
    // __language: squidexLanguage,
    __setTop: NumberOfRecords,
  }

  return cmsRequest(state.value.project, query, variables, language)
}
async function fetchLeaderships(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  // const project = projectStore.getters.selectedProject.value
  const query = leadershipQuery.loc.source.body as string
  // const squidexLanguage = BLCToSLC[language]
  const variables = {
    __setTop: NumberOfRecords,
    // __language: squidexLanguage,
  }

  return cmsRequest(state.value.project, query, variables, language)
}
async function fetchReflections(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = reflectionQuery.loc.source.body as string
  const toolType = state.value.project
  // toolType == PROJECT_NAME.videotool
  //   ? (query = videoReflectionQuery as string)
  //   : (query = reflectionQuery as string)
  // const squidexLanguage = BLCToSLC[language]
  const variables = {
    // __language: squidexLanguage,
    __setTop: NumberOfRecords,
  }

  return cmsRequest(toolType, query, variables, language)
}

async function fetchPrivacy(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = privacyQuery.loc.source.body as string
  // const squidexLanguage = BLCToSLC[language]
  const variables = {
    // __language: squidexLanguage,
    __setTop: NumberOfRecords,
  }

  return cmsRequest(PROJECT_NAME.simulator, query, variables, language)
}

async function fetchLandingPage(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = LandingPageQuery.loc.source.body as string
  // const squidexLanguage = BLCToSLC[language]
  const variables = {
    // __language: squidexLanguage,
    __setTop: NumberOfRecords,
  }

  return cmsRequest(PROJECT_NAME.simulator, query, variables, language)
}
async function fetchDashboard(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = dashboardQuery.loc.source.body as string
  // const squidexLanguage = BLCToSLC[language]
  const variables = {
    // __language: squidexLanguage,
    __setTop: NumberOfRecords,
  }

  return cmsRequest(state.value.project, query, variables, language)
}

async function fetchObservationTheory(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = ObservationTheoryQuery.loc.source.body as string
  const variables = {
    __setTop: NumberOfRecords,
  }

  return cmsRequest(state.value.project, query, variables, language)
}

async function fetchObservationDetail(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = ObservationDetailQuery.loc.source.body as string
  const variables = {
    __setTop: NumberOfRecords,
  }

  return cmsRequest(state.value.project, query, variables, language)
}

async function fetchDimensions(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = DimensionsQuery.loc.source.body as string
  const variables = {
    __setTop: NumberOfRecords,
  }

  return cmsRequest(state.value.project, query, variables, language)
}

async function fetchObservationPlan(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = ObservationPlanQuery.loc.source.body as string
  const variables = {
    __setTop: NumberOfRecords,
  }

  return cmsRequest(state.value.project, query, variables, language)
}

async function fetchObservationForm(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = ObservationFormQuery.loc.source.body as string
  const variables = {
    __setTop: NumberOfRecords,
  }

  return cmsRequest(state.value.project, query, variables, language)
}

async function fetchObservationLearn(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = ObservationLearnQuery.loc.source.body as string
  const variables = {
    __setTop: NumberOfRecords,
  }

  return cmsRequest(state.value.project, query, variables, language)
}

async function fetchIndividualReflex(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = IndividualReflexQuery.loc.source.body as string
  const variables = {
    __setTop: NumberOfRecords,
  }

  return cmsRequest(state.value.project, query, variables, language)
}

async function fetchGuidance(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = GuidanceQuery.loc.source.body as string
  const variables = {
    __setTop: NumberOfRecords,
  }

  return cmsRequest(state.value.project, query, variables, language)
}
//Getters
interface Getters {
  items: ComputedRef<Item[]>
  selectedItem: ComputedRef<State['selectedItem']>
  leaderships: ComputedRef<State['leaderships']>
  reflections: ComputedRef<State['reflections']>
  project: ComputedRef<string>
  dashboard: ComputedRef<State['dashboard']>
  privacy: ComputedRef<State['privacy']>
  landingPage: ComputedRef<State['landingPage']>
  observationTool: ComputedRef<Observation | null>
  observationTheory: ComputedRef<State['observationTheory']>
  observationDetail: ComputedRef<State['observationDetail']>
  dimensions: ComputedRef<State['dimensions']>
  observationPlan: ComputedRef<State['observationPlan']>
  observationForm: ComputedRef<State['observationForm']>
  observationLearn: ComputedRef<State['observationLearn']>
  individualReflex: ComputedRef<State['individualReflex']>
  guidance: ComputedRef<State['guidance']>
}
const getters = {
  get items(): ComputedRef<Item[]> {
    return computed(() => Array.from(state.value.items.values()))
  },
  get selectedItem(): ComputedRef<State['selectedItem']> {
    return computed(() => state.value.selectedItem)
  },
  get leaderships(): ComputedRef<State['leaderships']> {
    return computed(() => state.value.leaderships)
  },
  get reflections(): ComputedRef<State['reflections']> {
    return computed(() => state.value.reflections)
  },
  get project(): ComputedRef<string> {
    return computed(() => state.value.project)
  },

  get dashboard(): ComputedRef<State['dashboard']> {
    return computed(() => state.value.dashboard)
  },
  get privacy(): ComputedRef<State['privacy']> {
    return computed(() => state.value.privacy)
  },
  get landingPage(): ComputedRef<State['landingPage']> {
    return computed(() => state.value.landingPage)
  },
  get observationTool(): ComputedRef<State['observation']> {
    return computed(() => state.value.observation)
  },
  get observationTheory(): ComputedRef<State['observationTheory']> {
    return computed(() => state.value.observationTheory)
  },
  get observationDetail(): ComputedRef<State['observationDetail']> {
    return computed(() => state.value.observationDetail)
  },
  get dimensions(): ComputedRef<State['dimensions']> {
    return computed(() => state.value.dimensions)
  },
  get observationPlan(): ComputedRef<State['observationPlan']> {
    return computed(() => state.value.observationPlan)
  },
  get observationForm(): ComputedRef<State['observationForm']> {
    return computed(() => state.value.observationForm)
  },
  get observationLearn(): ComputedRef<State['observationLearn']> {
    return computed(() => state.value.observationLearn)
  },
  get individualReflex(): ComputedRef<State['individualReflex']> {
    return computed(() => state.value.individualReflex)
  },
  get guidance(): ComputedRef<State['guidance']> {
    return computed(() => state.value.guidance)
  },
}
//Actions
interface Actions {
  getItem: () => Promise<void>
  selectItem: (index: string) => void
  updatePreviousItem: (nextId: string) => void
  getLeaderships: () => Promise<void>
  getReflections: () => Promise<void>
  getDashboardContents: () => Promise<void>
  getPrivacyContents: () => Promise<void>
  setselectedProject: (mode: PROJECT_NAME) => Promise<void>
  getLandingPageContents: () => Promise<void>
  getObservationToolContents: () => Promise<void>
  getObservationTheoryContents: () => Promise<void>
  getObservationDetailContents: () => Promise<void>
  getDimensionsContents: () => Promise<void>
  getObservationPlanContents: () => Promise<void>
  getObservationFormContents: () => Promise<void>
  getObservationLearnContents: () => Promise<void>
  getIndividualReflexContents: () => Promise<void>
  getGuidanceContents: () => Promise<void>
}

// get all Scenarios data  from CMS
const actions = {
  getLeaderships: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchLeaderships(language)
    state.value.leaderships.clear()
    if (response.data) {
      response.data?.leaderships?.forEach((i) => {
        if (i.data && i.id) {
          const d = i.data as unknown as LeadershipData
          state.value.leaderships.set(d.header.iv, new Leadership(d))
        }
      })
    }
  },
  getReflections: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchReflections(language)
    state.value.reflections.clear()
    if (response.data) {
      response.data?.reflections?.sort((a, b) => {
        const x = a.data as unknown as ReflectionData
        const y = b.data as unknown as ReflectionData
        return parseInt(x.index.iv) - parseInt(y.index.iv)
      })
      response.data?.reflections?.forEach((i) => {
        if (i.data && i.id) {
          const d = i.data as unknown as ReflectionData
          state.value.reflections.set(d.header.iv, new Reflection(d))
        }
      })
    }
  },
  getDashboardContents: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchDashboard(language)
    if (response.data?.dashboard) {
      state.value.dashboard = undefined // clear contents
      const i = response.data.dashboard[0]
      if (i.data && i.id) {
        const d = i.data as unknown as DashboardData
        state.value.dashboard = new Dashboard(d, i.id)
      }
    }
  },
  getPrivacyContents: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchPrivacy(language)
    if (response.data?.privacy) {
      const i = response.data.privacy[0]
      if (i.data && i.id) {
        const d = i.data as unknown as PrivacyData
        state.value.privacy = new Privacy(d, i.id)
      }
    }
  },
  getLandingPageContents: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchLandingPage(language)
    if (response.data?.landing) {
      const i = response.data.landing[0]
      if (i.data && i.id) {
        const d = i.data as unknown as LandingPageData
        state.value.landingPage = new LandingPage(d, i.id)
      }
    }
  },

  getItem: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchItem(language)
    if (response.data) {
      response.data?.items?.forEach((i) => {
        if (i.data && i.id) {
          const d = i.data as unknown as ItemData
          state.value.items.set(i.id, new Item(d, i.id))
        }
      })
    } else {
      const error = 'Item query contains no records'
      console.log(error)
    }

    return Promise.resolve()
  },

  // Called from the Items.vue, when changing the displayed question
  selectItem: function (index: string): void {
    if (index == '0') {
      const items: Array<Item> = Array.from(state.value.items.values())
      state.value.selectedItem = items.find((item) => item.indexID == '1')
    } else {
      if (state.value.items.get(index)) state.value.selectedItem = state.value.items.get(index)
    }
  },

  //Update previous item
  updatePreviousItem: function (nextId: string): void {
    if (state.value.selectedItem) {
      if (state.value.items.get(nextId)) {
        const nextItem: Item = state.value.items.get(nextId) as Item

        nextItem.previousItem = state.value.selectedItem.id
        state.value.items.set(nextId, nextItem)
      }
    }
  },
  // Replace the current list of participants with another
  setselectedProject: function (mode: PROJECT_NAME): Promise<void> {
    if (mode) {
      state.value.project = mode
    }
    return Promise.resolve()
  },
  getObservationToolContents: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const query = ObservationQuery.loc.source.body as string
    const variables = {
      __setTop: NumberOfRecords,
    }

    const response = await cmsRequest(state.value.project, query, variables, language)
    if (response?.data?.queryObservationtoolContents?.[0]) {
      const data = response.data.queryObservationtoolContents[0]
      if (data.data && data.id) {
        const observationData = data.data as unknown as ObservationData
        state.value.observation = new Observation(observationData, data.id)
      }
    }
  },
  getObservationTheoryContents: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchObservationTheory(language)
    if (response.data?.queryObservationteoriContents?.[0]) {
      const data = response.data.queryObservationteoriContents[0]
      if (data.data && data.id) {
        const theoryData = data.data as unknown as ObservationTheoryData
        state.value.observationTheory = new ObservationTheory(theoryData, data.id)
      }
    }
  },
  getObservationDetailContents: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchObservationDetail(language)
    if (response.data?.queryObservationdetailContents?.[0]) {
      const data = response.data.queryObservationdetailContents[0]
      if (data.data && data.id) {
        const detailData = data.data as unknown as ObservationDetailData
        state.value.observationDetail = new ObservationDetail(detailData, data.id)
      }
    }
  },
  getDimensionsContents: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchDimensions(language)
    if (response.data?.Dimensions?.[0]) {
      const data = response.data.Dimensions[0]
      if (data.data && data.id) {
        const dimensionsData = data.data as unknown as DimensionsData
        state.value.dimensions = new Dimensions(dimensionsData, data.id)
      }
    }
  },
  getObservationPlanContents: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchObservationPlan(language)
    if (response.data?.ObservationPlan?.[0]) {
      const data = response.data.ObservationPlan[0]
      if (data.data && data.id) {
        const planData = data.data as unknown as ObservationPlanData
        state.value.observationPlan = new ObservationPlan(planData, data.id)
      }
    }
  },
  getObservationFormContents: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchObservationForm(language)
    if (response.data?.ObservationForm?.[0]) {
      const data = response.data.ObservationForm[0]
      if (data.data && data.id) {
        const formData = data.data as unknown as ObservationFormData
        state.value.observationForm = new ObservationForm(formData, data.id)
      }
    }
  },
  getObservationLearnContents: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchObservationLearn(language)
    if (response.data?.ObservationLearn?.[0]) {
      const data = response.data.ObservationLearn[0]
      if (data.data && data.id) {
        const learnData = data.data as unknown as ObservationLearnData
        state.value.observationLearn = new ObservationLearn(learnData, data.id)
      }
    }
  },
  getIndividualReflexContents: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchIndividualReflex(language)
    if (response.data?.IndividualReflex?.[0]) {
      const data = response.data.IndividualReflex[0]
      if (data.data && data.id) {
        const reflexData = data.data as unknown as IndividualReflexData
        state.value.individualReflex = new IndividualReflex(reflexData, data.id)
      }
    }
  },
  getGuidanceContents: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchGuidance(language)
    if (response.data?.Guidance?.[0]) {
      const data = response.data.Guidance[0]
      if (data.data && data.id) {
        const guidanceData = data.data as unknown as GuidanceData
        state.value.guidance = new Guidance(guidanceData, data.id)
      }
    }
  },
}
// This defines the interface used externally
interface ServiceInterface {
  actions: Actions
  getters: Getters
}
export const useCMSStore = () => ({
  getters,
  actions,
  state,
})

export type CMSStoreType = ReturnType<typeof useCMSStore>

<template>
  <div class="grid grid-rows-1 justify-items-center">
    <div class="lg:w-5/6 xl:w-3/6 bg-white rounded-lg -mt-6">
      <div class="flex justify-between">
        <h1 class="text-3xl fontLight mb-6 text-center w-full">{{ title.heading }}</h1>
        <span class="cursor-pointer text-xl" @click="close">X</span>
      </div>

      <div class="mb-8">
        <p class="text-base font text-center w-full">{{ title.subHeading }}</p>
      </div>
      <div v-if="expand == false && mode === PROJECT_NAME.simulator">
        <div v-for="(leadership, index) in leadershipKeys" :key="index">
          <div class="mb-4">
            <div
              class="flex rounded-lg overflow-hidden cursor-pointer border rounded-lg shadow-sm border-b-2"
              @click="toggleSection(leadership)"
            >
              <div :class="leadership" class="w-1/3 p-0 flex justify-center rounded-l-lg">
                <img class="rounded-full h-28 w-28 object-cover" :src="`/images/${leadership}.svg`" />
              </div>

              <div class="w-2/3 bg-white p-4 flex justify-between items-center">
                <div class="flex flex-col items-center justify-center w-full">
                  <p class="text-2xl">{{ leadership }}</p>
                  <!-- <SlButton
                  :class="leadership + 'Button h-8 mt-5 w-28 focus:outline-none btn_text text-white text-sm'"
                  :custom-width="'8rem'"
                  @click.stop="readMore(leadership)"
                >
                  Les mer
                </SlButton> -->
                </div>
                <span
                  class="transform transition-all duration-300"
                  :class="expandedSections.includes(leadership) ? 'rotate-180' : ''"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M7 10L12 15L17 10"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <transition name="expand">
              <div
                v-if="expandedSections.includes(leadership)"
                class="bg-white px-6 py-2 rounded-b-lg shadow-sm mt-1 border shadow-sm border-b-4 border-t-0 rich-text-content"
              >
                <p class="text-xl text-left text-black">{{ leadership }}</p>
                <div
                  v-if="mode === PROJECT_NAME.simulator"
                  class="border-b rounded-lg p-2 opacity-40 -ml-6 -mr-6"
                  :class="leadership + 'Border'"
                ></div>
                <p
                  class="text-base font text-left !text-left mt-4"
                  v-if="getLeadershipContent(leadership)?.content"
                  v-html="getLeadershipContent(leadership)?.content"
                ></p>
                <p
                  class="text-base font text-left !text-left pt-4"
                  v-if="getLeadershipContent(leadership)?.introduction"
                  v-html="getLeadershipContent(leadership)?.introduction"
                ></p>
              </div>
            </transition>
          </div>
        </div>
        <SlButton
          class="buttonbackgroundColor h-8 mt-12 w-28 focus:outline-none btn_text text-white text-sm"
          :custom-width="'8rem'"
          @click="close"
        >
          Tilbake
        </SlButton>
      </div>
      <div v-else-if="expand == false && mode == PROJECT_NAME.survey">
        <div v-for="(leadership, index) in leadershipKeys" :key="index" class="mb-4 rounded-lg shadow-sm border-b-2">
          <div
            @click="toggleTab(index)"
            class="flex justify-between p-4 bg-white rounded-lg cursor-pointer hover:bg-gray-50 transition-colors shadow-sm border border-gray"
          >
            <h2 class="text-xl font-medium">{{ leadership }}</h2>
            <span
              class="transform transition-transform duration-300"
              :class="activeTab.includes(index) ? 'rotate-180' : ''"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M7 10L12 15L17 10"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <transition name="collapse">
            <div v-show="activeTab.includes(index)" class="bg-white p-6 rounded-b-lg shadow-sm mt-1 border-t-0 border">
              <div v-if="getLeadershipContent(leadership)?.introduction" class="mb-4">
                <p class="text-base font-normal !text-left" v-html="getLeadershipContent(leadership)?.introduction"></p>
              </div>
              <div v-if="getLeadershipContent(leadership)?.content">
                <p class="text-base font-normal !text-left" v-html="getLeadershipContent(leadership)?.content"></p>
              </div>
            </div>
          </transition>
        </div>
        <SlButton
          class="buttonbackgroundColor h-8 mt-12 w-28 focus:outline-none btn_text text-white text-sm"
          :custom-width="'8rem'"
          @click="close"
        >
          Tilbake
        </SlButton>
      </div>

      <div v-else-if="expand == false && mode === PROJECT_NAME.videotool">
        <div v-for="(leadership, index) in leadershipKeys" :key="index" class="mb-4 rounded-lg shadow-sm border-b-4">
          <div
            @click="toggleTab(index)"
            class="flex justify-between p-4 bg-white rounded-lg cursor-pointer hover:bg-gray-50 transition-colors shadow-sm border-2 border-gray"
          >
            <h2 class="text-xl font-medium">{{ leadership }}</h2>
            <span
              class="transform transition-transform duration-300"
              :class="activeTab.includes(index) ? 'rotate-180' : ''"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M7 10L12 15L17 10"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <transition name="collapse">
            <div v-show="activeTab.includes(index)" class="bg-white p-6 rounded-b-lg shadow-sm mt-1 border-t-0 border">
              <div v-if="getLeadershipContent(leadership)?.introduction" class="mb-4">
                <p class="text-base font-normal !text-left" v-html="getLeadershipContent(leadership)?.introduction"></p>
              </div>
              <div v-if="getLeadershipContent(leadership)?.content">
                <p class="text-base font-normal !text-left" v-html="getLeadershipContent(leadership)?.content"></p>
              </div>
            </div>
          </transition>
        </div>
        <SlButton
          class="buttonbackgroundColor h-8 mt-12 w-28 focus:outline-none btn_text text-white text-sm"
          :custom-width="'8rem'"
          @click="close"
        >
          Tilbake
        </SlButton>
      </div>
      <div v-else-if="expand == false && mode === PROJECT_NAME.guides">
        <div v-for="(leadership, index) in leadershipKeys" :key="index">
          <div class="mb-4">
            <div
              class="flex rounded-lg overflow-hidden cursor-pointer border shadow-sm border-b-2"
              @click="toggleSection(leadership)"
            >
              <div
                :class="leadership"
                class="w-1/3 p-2 flex justify-center rounded-l-lg"
                :style="mode === PROJECT_NAME.guides ? getItemColor(leadership) : ''"
              >
                <img
                  class="object-cover"
                  :style="
                    GUIDES[leadership as keyof typeof GUIDES] === 'graduation'
                      ? { height: '140px', width: '140px', marginTop: '-30px' }
                      : { height: '112px', width: '112px' }
                  "
                  :class="{ 'rounded-full': GUIDES[leadership as keyof typeof GUIDES] === 'Competance' }"
                  :src="getGuideImage(leadership)"
                  @error="console.log('Failed to load image for leadership:', leadership)"
                />
              </div>

              <div class="w-2/3 bg-white p-4 flex justify-between items-center">
                <div class="flex flex-col items-center justify-center w-full">
                  <p class="text-2xl text-center">{{ leadership }}</p>
                  <!-- <SlButton
                  :class="leadership + 'Button h-8 mt-5 w-28 focus:outline-none btn_text text-white text-sm'"
                  :custom-width="'8rem'"
                  @click.stop="readMore(leadership)"
                >
                  Les mer
                </SlButton> -->
                </div>
                <span
                  class="transform transition-all duration-300"
                  :class="expandedSections.includes(leadership) ? 'rotate-180' : ''"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M7 10L12 15L17 10"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <transition name="expand">
              <div
                v-if="expandedSections.includes(leadership)"
                class="bg-white px-6 py-2 rounded-b-lg mt-1 shadow-sm border border-b-4 border-t-0 rich-text-content"
              >
                <p class="text-xl text-left">{{ leadership }}</p>
                <div
                  class="border-b rounded-lg p-2 opacity-40 -ml-6 -mr-6"
                  :style="{ borderColor: getItemColor(leadership).match(/#[0-9A-Fa-f]{6}/)?.[0] || '#000000' }"
                ></div>
                <p
                  class="text-base font text-left !text-left mt-4"
                  v-if="getLeadershipContent(leadership)?.content"
                  v-html="getLeadershipContent(leadership)?.content"
                ></p>
                <p
                  class="text-base font text-left !text-left pt-4"
                  v-if="getLeadershipContent(leadership)?.introduction"
                  v-html="getLeadershipContent(leadership)?.introduction"
                ></p>
                <div class="quotes-section mt-6">
                  <div class="quotes-list">
                    <div
                      v-for="(quote, quoteIndex) in getLeadershipContent(leadership)?.quotes"
                      :key="quoteIndex"
                      class="quote-item"
                    >
                      <div v-if="quoteIndex === 0" class="quote-divider">
                        <span
                          class="quote-mark Arial flip-h"
                          :style="mode === PROJECT_NAME.guides ? getQuoteStyle(leadership) : ''"
                        >
                          &#8221;
                        </span>
                        <div
                          class="w-full border-b rounded-lg p-2 opacity-40"
                          :style="
                            mode === PROJECT_NAME.guides ? { ...getQuoteDividerStyle(leadership), opacity: 0.3 } : ''
                          "
                        />
                        <span
                          class="quote-mark Arial"
                          :style="mode === PROJECT_NAME.guides ? getQuoteStyle(leadership) : ''"
                        >
                          &#8221;
                        </span>
                      </div>
                      <p v-if="quote.quotetitle !== ''" class="quote-text">
                        {{ quote.quotetitle }}
                      </p>
                      <div
                        v-if="quoteIndex < (getLeadershipContent(leadership)?.quotes?.length || 0) - 1"
                        class="border-b rounded-lg p-2 opacity-40 -ml-6 -mr-6"
                        :style="
                          mode === PROJECT_NAME.guides ? { ...getQuoteDividerStyle(leadership), opacity: 0.3 } : ''
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div>
          <p class="text-2xl mt-4 mb-4">Videre lesing:</p>
          <ul class="text-base" style="list-style-type: disc; font-family: 'Inter'; color: #686868">
            <li>
              (Jacobsen & Gunnulfsen, 2023; Jacobsen, Jensen, & Lejonberg, 2023; Jacobsen & Lejonberg, 2024;
              Kunnskapsdepartementet, 2017; Lejonberg, Jacobsen, & Mangen, 2022; Regjeringen, 2009;
              Utdanningdirektoratet, 2019)
            </li>
            <li>
              Jacobsen, H. M., & Gunnulfsen, A. E. (2023). Dealing with policy expectations of mentoring newly qualified
              teachers–a Norwegian example. Scandinavian Journal of Educational Research, 1-15.
            </li>
            <li>
              Jacobsen, H. M., Jensen, R., & Lejonberg, E. (2023). Tracing ideas about mentoring newly qualified
              teachers and the expectations of school leadership in policy documents. International Journal of
              Leadership in Education, 1-23.
            </li>
            <li>
              Jacobsen, H. M., & Lejonberg, E. (2024). Organizing mentoring and induction practices: understood through
              the eyes of newly qualified teachers. Mentoring & tutoring, 32(4), 395-418.
              doi:10.1080/13611267.2024.2360603
            </li>
            <li>
              Kunnskapsdepartementet. (2017). Lærerutdanning 2025. Nasjonal strategi for kvalitet og samarbeid i
              lærerutdanningene. Retrieved from
              https://www.regjeringen.no/contentassets/d0c1da83bce94e2da21d5f631bbae817/kd_nasjonal-strategi-for-larerutdanningene_nett.pdf
            </li>
            <li>
              Lejonberg, E., Jacobsen, H. M., & Mangen, O. T. (2022). Ledelse av profesjonsfellesskapet: Å anerkjenne
              nyutdannede lærere som ressurser. Skolelederen(2), 22-25.
            </li>
            <li>
              Regjeringen. (2009). St.meld. nr. 11 Læreren Rollen og utdanningen. Retrieved from
              https://www.regjeringen.no/no/dokumenter/stmeld-nr-11-2008-2009-/id544920/
            </li>
            <li>
              Utdanningdirektoratet. (2019). Veiledning av nyutdannede – hvordan kan det gjennomføres? Retrieved from
              https://www.udir.no/kvalitet-og-kompetanse/veiledning-av-nyutdannede/hvordan-kan-det-gjennomfores
            </li>
          </ul>
        </div>

        <SlButton
          class="buttonbackgroundColor h-8 mt-12 w-28 focus:outline-none btn_text text-white text-sm"
          :custom-width="'8rem'"
          @click="close"
        >
          Tilbake
        </SlButton>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import SlButton from '@/components/base/SlButton.vue'

  import Leadership from './LeadershipItem.vue'
  import { useCMSStore } from '../store/useCMSStore'

  import { PROJECT_NAME, GUIDES } from '@/constants'
  import { defineComponent, ref, PropType, computed, onMounted } from 'vue'
  import Autoritær from '@/assets/Autoritær.svg'
  import Autoritativ from '@/assets/Autoritativ.svg'

  import Demokratisk from '@/assets/Demokratisk.svg'
  import Ettergivende from '@/assets/Ettergivende.svg'

  interface LeadershipTitle {
    heading: string
    subHeading: string
  }
  export default defineComponent({
    name: 'LeadershipsComponent',
    components: { SlButton, Leadership },
    props: {
      mode: { type: String, required: true },
      title: { type: Object as PropType<LeadershipTitle>, required: true },
    },
    emits: ['close-popup', 'update-show'],
    setup(props, context) {
      const { getters: cmsGetters } = useCMSStore()

      const selectedLeadership = ref('')
      const expand = ref(false)
      const flip = ref(false)
      const forward = () => {
        if (props.mode == 'v4v') context.emit('update-show')
      }
      const getImage = (leadership: string) => {
        return `/images/${leadership}.svg`
      }
      const scroll = () => {
        const ele = document.getElementById('selectedLeadership')
        if (ele !== null) ele.scrollIntoView()
      }
      const closePopup = () => {
        selectedLeadership.value = ''
        expand.value = !expand.value
      }
      const readMore = (leadership: string) => {
        selectedLeadership.value = leadership
        scroll()
        if (props.mode == PROJECT_NAME.survey) {
          flip.value = true
        } else expand.value = !expand.value
      }
      const flipCard = () => {
        flip.value = false
        selectedLeadership.value = ''
      }
      function close() {
        context.emit('close-popup')
      }
      const expandedSections = ref<string[]>([])
      const toggleSection = (leadership: string) => {
        const index = expandedSections.value.indexOf(leadership)
        if (index === -1) {
          expandedSections.value.push(leadership)
        } else {
          expandedSections.value.splice(index, 1)
        }
      }
      const getLeadershipContent = (leadership: string) => {
        return cmsGetters.leaderships.value.get(leadership)
      }

      const activeTab = ref<number[]>([])
      const toggleTab = (index: number) => {
        const currentIndex = activeTab.value.indexOf(index)
        if (currentIndex === -1) {
          activeTab.value.push(index)
        } else {
          activeTab.value.splice(currentIndex, 1)
        }
      }

      const getGuideImage = (leadership: string) => {
        const guideKey = leadership as keyof typeof GUIDES
        if (guideKey in GUIDES) {
          return `/images/${GUIDES[guideKey]}.svg`
        }
        return `/images/${leadership}.svg` // fallback
      }
      const getItemColor = (leadership: string) => {
        const colorMap: { [key in (typeof GUIDES)[keyof typeof GUIDES]]?: string } = {
          [GUIDES['Styringsdokumenter om nyutdannede som ressurs']]: 'background-color: #144578;',
          [GUIDES['Styringsdokumenter om profesjonsfellesskapet og veileders rolle']]: 'background-color: #fd8204;',
          [GUIDES['Nyutdannedes kompetanse i profesjonsfellesskapet']]: 'background-color: #f7e922;',
          [GUIDES['Skolekultur, ledelse og samarbeid']]: 'background-color: #05d394;',
        }
        const guideKey = leadership as keyof typeof GUIDES
        return colorMap[GUIDES[guideKey]] || ''
      }

      const extractColor = (styleString: string) => {
        // Extract color from "background-color: #144578;" format
        const match = styleString.match(/#[0-9A-Fa-f]{6}/)
        return match ? match[0] : '#000000'
      }

      const getQuoteStyle = (leadership: string) => {
        const color = extractColor(getItemColor(leadership))
        return { color }
      }

      const getQuoteDividerStyle = (leadership: string) => {
        const borderColor = extractColor(getItemColor(leadership))
        return { borderColor }
      }

      return {
        PROJECT_NAME,
        GUIDES,
        leadershipKeys: computed(() => {
          return Array.from(cmsGetters.leaderships.value.keys())
        }),
        leadershipContent: computed(() => cmsGetters.leaderships.value.get(selectedLeadership.value)),
        selectedLeadership,
        expand,
        leaderships: computed(() => cmsGetters.leaderships.value),
        flip,
        forward,
        getImage,
        //Methods
        scroll,
        closePopup,
        readMore,
        flipCard,
        Autoritær,
        Autoritativ,
        Demokratisk,
        Ettergivende,
        close,
        expandedSections,
        toggleSection,
        getLeadershipContent,
        activeTab,
        toggleTab,
        getGuideImage,
        getItemColor,
        getQuoteStyle,
        getQuoteDividerStyle,
      }
    },
  })
</script>

<style scoped>
  .fontLight {
    letter-spacing: 0px;
    text-align: center;
    font-weight: 300;
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }
  .quote {
    background-image: url('@/assets/icons/svg/SpeechBubble.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .font {
    letter-spacing: 0px;
    text-align: center;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
  }
  .Autoritær {
    background-color: #144578;
  }
  .Demokratisk {
    background-color: #fd8204;
  }
  .Autoritativ {
    background-color: #feef35;
    color: var(--texte_grey_black, #535050);
  }
  .Ettergivende {
    background-color: #05d394;
  }
  .AutoritærButton {
    background-color: #0d3258;
  }
  .DemokratiskButton {
    background-color: #e67301;
  }
  .AutoritativButton {
    background-color: #f0dd0b;
  }
  .EttergivendeButton {
    background-color: #08af7c;
  }
  .buttonBackground {
    background-color: var(--greylightblue_, #cfd2dd);
  }
  .Schoolculture {
    background-color: #05d394;
  }
  .SchoolcultureButton {
    background-color: #08af7c;
  }
  .Competance {
    background-color: #f7e922;
  }
  .CompetanceButton {
    background-color: #f0dd0b;
  }
  .ManagementDocumentProfessional {
    background-color: #fd8204;
  }
  .ManagementDocumentProfessionalButton {
    background-color: #e67301;
  }
  .ManagementDocumentNewGraduate {
    background-color: #144578;
  }
  .ManagementDocumentNewGraduateButton {
    background-color: #0d3258;
  }

  .leadership-font {
    word-wrap: break-word;
    word-break: break-word;
    font-family: 'Inter-Medium', 'Inter', sans-serif;
    font-size: 16px;
  }
  .text {
    overflow: visible;
    white-space: pre;
    letter-spacing: 0px;
    line-height: 1.6;
    color: #000000;
    font-weight: 500;
    font-family: 'Inter-Medium', 'Inter', sans-serif;
    font-size: 22px;
  }
  .expand-enter-active,
  .expand-leave-active {
    transition: all 0.3s ease-in-out;
    max-height: 1000px;
    opacity: 1;
  }

  .expand-enter-from,
  .expand-leave-to {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }
  :deep(.rich-text-content ul) {
    list-style-type: disc;
    padding-left: 1.5em;
  }

  :deep(.rich-text-content li) {
    display: list-item;
  }
  .collapse-enter-active,
  .collapse-leave-active {
    transition: max-height 0.3s ease-out;
    max-height: 800px;
    overflow: hidden;
  }

  .collapse-enter-from,
  .collapse-leave-to {
    max-height: 0;
    overflow: hidden;
  }

  .Arial {
    font-family: Arial, sans-serif;
  }
  .quotes-section {
    margin: 12px auto;
  }

  .quote-divider {
    display: flex;
    align-items: center;
    margin: -0.5rem 0;
  }

  .quote-mark {
    font-size: 4rem;
    line-height: 1;
    padding: 0 0.5rem;
    position: relative;
    top: 1rem;
  }

  .divider-line {
    flex-grow: 1;
  }

  .quote-text {
    font-size: 0.875rem;
    line-height: 1.5;
    text-align: left;
    padding: 0.75rem 1rem;
    color: #4b5563;
  }

  .flip-h {
    display: inline-block;
    transform: scaleX(-1);
  }

  .AutoritærBorder {
    border-color: #144578;
  }
  .DemokratiskBorder {
    border-color: #fd8204;
  }
  .AutoritativBorder {
    border-color: #feef35;
  }
  .EttergivendeBorder {
    border-color: #05d394;
  }
</style>

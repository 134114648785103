<template>
  <div>
    <div
      v-if="(teachersReview && teachersReview.review.size >= 0) || (studentsReview && studentsReview.review.size >= 0)"
      class="relative h-full w-full flex justify-center items-center"
    >
      <div id="spider-chart"></div>

      <div v-if="studentsReview" class="mt-5 mr-5 legend0 text-white p-2 rounded-full">
        {{ studentsReview.title }}
      </div>

      <div v-if="teachersReview" class="mt-5 mr-5 legend1 text-white p-2 rounded-full">
        {{ teachersReview.title }}
      </div>
    </div>

    <div v-else class="relative h-full w-full flex justify-center items-center">empty respons</div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted } from 'vue'
  import { setupRadarChart } from '@/graphs/radar-chart.js'
  import { useSurveyStore } from '../store/useSurveyStore'
  import type { Reviews } from '@/types/surveyModel'

  export default defineComponent({
    setup() {
      const { getters: surveyGetters } = useSurveyStore()

      const studentsReview = surveyGetters.studentsReview
      const teachersReview = surveyGetters.teachersReview

      onMounted(() => {
        if (
          studentsReview.value?.review &&
          teachersReview.value?.review &&
          studentsReview.value.review.size >= 0 &&
          teachersReview.value.review.size >= 0
        ) {
          const reviews: Reviews[] = [studentsReview.value, teachersReview.value].filter(
            (r): r is Reviews => r !== undefined,
          )

          setupRadarChart('#spider-chart', reviews)
        }
      })

      return {
        studentsReview,
        teachersReview,
      }
    },
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: none;
  }

  .legend0 {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #cc333f;
  }

  .legend1 {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #00a0b0;
  }
</style>

<template>
  <div v-if="!showLearn" class="grid grid-rows-1 justify-items-center">
    <div class="lg:w-5/6 xl:w-3/6 bg-white rounded-lg -mt-6">
      <div class="flex justify-between">
        <h1 class="text-3xl fontLight mb-6 text-center w-full">{{ observationForm?.heading }}</h1>
        <span class="cursor-pointer text-xl" @click="closeDetails">X</span>
      </div>

      <div class="mb-8 bg-gray-100 p-4 rounded-lg">
        <h2 class="text-xl mb-4">{{ observationForm?.title }}</h2>
        <p class="text-base font">{{ observationForm?.description }}</p>
      </div>

      <!-- Form fields -->
      <div class="space-y-8">
        <div>
          <h3 class="text-lg mb-2">{{ observationForm?.formfields[0].field }}</h3>
          <textarea
            v-model="formData.focus"
            class="w-full p-3 border rounded-lg resize-none"
            rows="4"
            :placeholder="observationForm?.formfields[0].placeholder"
            maxlength="500"
          ></textarea>
          <div class="text-right text-sm text-gray-500">
            {{ formData.focus.length }}/{{ observationForm?.formfields[0].maxlength }}
          </div>
        </div>

        <div>
          <h3 class="text-lg mb-2">{{ observationForm?.formfields[1].field }}</h3>
          <textarea
            v-model="formData.definition"
            class="w-full p-3 border rounded-lg resize-none"
            rows="4"
            :placeholder="observationForm?.formfields[1].placeholder"
            maxlength="500"
          ></textarea>
          <div class="text-right text-sm text-gray-500">
            {{ formData.definition.length }}/{{ observationForm?.formfields[1].maxlength }}
          </div>
        </div>

        <div>
          <h3 class="text-lg mb-2">{{ observationForm?.formfields[2].field }}</h3>
          <textarea
            v-model="formData.indicators"
            class="w-full p-3 border rounded-lg resize-none"
            rows="4"
            :placeholder="observationForm?.formfields[2].placeholder"
            maxlength="500"
          ></textarea>
          <div class="text-right text-sm text-gray-500">
            {{ formData.indicators.length }}/{{ observationForm?.formfields[2].maxlength }}
          </div>
        </div>

        <button
          class="w-full p-4 border rounded-lg text-center hover:bg-gray-50 flex items-center justify-center gap-2"
          @click="generateWord"
        >
          <span>{{ observationForm?.buttontext }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>

      <!-- Show 7cs here -->
      <div v-if="dimensions">
        <h1 class="text-3xl fontLight mb-12 mt-12 text-center">{{ dimensions?.title }}</h1>
        <div v-for="(tab, index) in dimensions?.tabs" :key="index" class="mb-4">
          <div
            @click="toggleTab(index)"
            class="flex justify-between items-center p-4 bg-white rounded-lg cursor-pointer hover:bg-gray-50 transition-colors shadow-sm border border-b-2"
          >
            <h2 class="text-xl font-medium">{{ tab.heading }}</h2>
            <span
              class="transform transition-transform duration-300"
              :class="activeTab.includes(index) ? 'rotate-180' : ''"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M7 10L12 15L17 10"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <transition name="collapse">
            <div
              v-show="activeTab.includes(index)"
              class="bg-white p-6 rounded-b-lg shadow-sm mt-1 border-t-0 border border-b-2"
            >
              <p class="text-base font" v-html="tab.content"></p>
            </div>
          </transition>
        </div>
      </div>

      <div class="flex justify-between mt-12 mb-12">
        <SlButton
          class="buttonbackgroundColor h-8 mt-5 w-28 focus:outline-none btn_text text-white text-sm"
          :custom-width="'8rem'"
          @click="previousContent"
        >
          Tilbake
        </SlButton>
        <SlButton
          class="buttonbackgroundColor h-8 mt-5 w-28 focus:outline-none btn_text text-white text-sm"
          :custom-width="'8rem'"
          @click="nextContent"
        >
          Neste
        </SlButton>
      </div>
    </div>
  </div>
  <ObservationLearn v-else :mode="mode" @close-popup="closeDetails" @switch-component="handleSwitchComponent" />
</template>

<script lang="ts">
  import { defineComponent, ref, computed, onMounted } from 'vue'
  import SlButton from '@/components/base/SlButton.vue'
  import { useCMSStore } from '@/store/useCMSStore'
  import {
    Document,
    Paragraph,
    Packer,
    TextRun,
    HeadingLevel,
    AlignmentType,
    Table,
    TableRow,
    TableCell,
    WidthType,
    BorderStyle,
  } from 'docx'
  import { saveAs } from 'file-saver'
  import ObservationLearn from './ObservationLearn.vue'

  export default defineComponent({
    name: 'ObservationFormItem',
    components: {
      SlButton,
      ObservationLearn,
    },
    props: {
      mode: { type: String, required: true },
    },
    emits: ['close-popup', 'switch-component', 'switch-component-learn'],
    setup(props, { emit }) {
      const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
      const dimensions = computed(() => cmsGetters.dimensions.value)
      const observationForm = computed(() => cmsGetters.observationForm.value)
      const showForm = ref(false)
      const formData = ref({
        focus: '',
        definition: '',
        indicators: '',
      })

      onMounted(() => {
        cmsActions.getDimensionsContents()
        cmsActions.getObservationFormContents()
      })

      const showLearn = ref(false)

      const closeDetails = () => {
        emit('close-popup')
      }

      const handleSwitchComponent = (component: string) => {
        if (component === 'form') {
          showLearn.value = false
        }
      }

      const previousContent = () => {
        emit('switch-component', 'plan')
      }

      const nextContent = () => {
        showLearn.value = true
      }

      const generateWord = async () => {
        try {
          const doc = new Document({
            sections: [
              {
                properties: {},
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Hva skal vi se/høre etter?',
                      }),
                    ],
                    heading: HeadingLevel.HEADING_2,
                  }),
                  new Paragraph({
                    text: formData.value.focus || '',
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Definisjon:',
                      }),
                    ],
                    heading: HeadingLevel.HEADING_2,
                  }),
                  new Paragraph({
                    text: formData.value.definition || '',
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Indikasjoner:',
                      }),
                    ],
                    heading: HeadingLevel.HEADING_2,
                  }),
                  new Paragraph({
                    text: formData.value.indicators || '',
                  }),
                  new Table({
                    width: {
                      size: 100,
                      type: WidthType.PERCENTAGE,
                    },
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [
                              new Paragraph({
                                text: 'Observasjoner',
                                heading: HeadingLevel.HEADING_2,
                              }),
                            ],
                          }),
                          new TableCell({
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [
                              new Paragraph({
                                text: 'Tolkning/spørsmål til veiledning',
                                heading: HeadingLevel.HEADING_2,
                              }),
                            ],
                          }),
                        ],
                      }),
                      new TableRow({
                        height: {
                          value: 8000,
                          rule: 'atLeast',
                        },
                        children: [
                          new TableCell({
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [new Paragraph('')],
                          }),
                          new TableCell({
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [new Paragraph('')],
                          }),
                        ],
                      }),
                    ],
                    borders: {
                      top: { style: BorderStyle.SINGLE, size: 1 },
                      bottom: { style: BorderStyle.SINGLE, size: 1 },
                      left: { style: BorderStyle.SINGLE, size: 1 },
                      right: { style: BorderStyle.SINGLE, size: 1 },
                      insideHorizontal: { style: BorderStyle.SINGLE, size: 1 },
                      insideVertical: { style: BorderStyle.SINGLE, size: 1 },
                    },
                  }),
                  new Paragraph({
                    pageBreakBefore: true,
                  }),
                  new Table({
                    width: {
                      size: 100,
                      type: WidthType.PERCENTAGE,
                    },
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [
                              new Paragraph({
                                text: 'Observasjoner',
                                heading: HeadingLevel.HEADING_2,
                              }),
                            ],
                          }),
                          new TableCell({
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [
                              new Paragraph({
                                text: 'Tolkning/spørsmål til veiledning',
                                heading: HeadingLevel.HEADING_2,
                              }),
                            ],
                          }),
                        ],
                      }),
                      new TableRow({
                        height: {
                          value: 8000,
                          rule: 'atLeast',
                        },
                        children: [
                          new TableCell({
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [new Paragraph('')],
                          }),
                          new TableCell({
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [new Paragraph('')],
                          }),
                        ],
                      }),
                    ],
                    borders: {
                      top: { style: BorderStyle.SINGLE, size: 1 },
                      bottom: { style: BorderStyle.SINGLE, size: 1 },
                      left: { style: BorderStyle.SINGLE, size: 1 },
                      right: { style: BorderStyle.SINGLE, size: 1 },
                      insideHorizontal: { style: BorderStyle.SINGLE, size: 1 },
                      insideVertical: { style: BorderStyle.SINGLE, size: 1 },
                    },
                  }),
                  new Paragraph({
                    pageBreakBefore: true,
                  }),
                  new Table({
                    width: {
                      size: 100,
                      type: WidthType.PERCENTAGE,
                    },
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [
                              new Paragraph({
                                text: 'Observasjoner',
                                heading: HeadingLevel.HEADING_2,
                              }),
                            ],
                          }),
                          new TableCell({
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [
                              new Paragraph({
                                text: 'Tolkning/spørsmål til veiledning',
                                heading: HeadingLevel.HEADING_2,
                              }),
                            ],
                          }),
                        ],
                      }),
                      new TableRow({
                        height: {
                          value: 8000,
                          rule: 'atLeast',
                        },
                        children: [
                          new TableCell({
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [new Paragraph('')],
                          }),
                          new TableCell({
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [new Paragraph('')],
                          }),
                        ],
                      }),
                    ],
                    borders: {
                      top: { style: BorderStyle.SINGLE, size: 1 },
                      bottom: { style: BorderStyle.SINGLE, size: 1 },
                      left: { style: BorderStyle.SINGLE, size: 1 },
                      right: { style: BorderStyle.SINGLE, size: 1 },
                      insideHorizontal: { style: BorderStyle.SINGLE, size: 1 },
                      insideVertical: { style: BorderStyle.SINGLE, size: 1 },
                    },
                  }),
                ],
              },
            ],
          })

          const blob = await Packer.toBlob(doc)
          saveAs(blob, 'observasjonsskjema.docx')
        } catch (error) {
          console.error('Error generating document:', error)
          alert('Det oppstod en feil ved generering av dokumentet')
        }
      }

      const activeTab = ref<number[]>([])
      const toggleTab = (index: number) => {
        const currentIndex = activeTab.value.indexOf(index)
        if (currentIndex === -1) {
          activeTab.value.push(index)
        } else {
          activeTab.value.splice(currentIndex, 1)
        }
      }

      return {
        formData,
        closeDetails,
        previousContent,
        nextContent,
        generateWord,
        handleSwitchComponent,
        dimensions,
        activeTab,
        toggleTab,
        observationForm,
        showLearn,
        showForm,
      }
    },
  })
</script>

<style scoped>
  .fontLight {
    letter-spacing: 0px;
    font-weight: 300;
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }

  .font {
    letter-spacing: 0px;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
  }

  .btn_text {
    font-family: 'Inter-Medium', 'Inter', sans-serif;
  }

  .buttonbackgroundColor {
    background-color: var(--Canvas_Bluelight, #1a6899);
  }
  .collapse-enter-active,
  .collapse-leave-active {
    transition: all 0.3s ease-in-out;
    max-height: 300px;
    opacity: 1;
  }

  .collapse-enter-from,
  .collapse-leave-to {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }

  .dimension-content {
    background-color: white;
    border-radius: 0 0 0.5rem 0.5rem;
  }
</style>

<template>
  <div v-if="!showPlan && !showTheory" class="grid grid-rows-1 justify-items-center">
    <div class="lg:w-5/6 xl:w-3/6 bg-white rounded-lg -mt-6">
      <div class="flex justify-between">
        <h1 class="text-3xl fontLight mb-6 text-center w-full">
          {{ observationDetailContent?.title }}
          <span class="cursor-pointer underline" @click="handleObservationClick">Observasjon</span>
        </h1>
        <span class="cursor-pointer text-xl" @click="closeDetails">X</span>
      </div>

      <div class="mb-8">
        <p class="text-base font text-center w-full" v-html="observationDetailContent?.content"></p>
      </div>

      <!-- div for image -->
      <div class="my-24">
        <img
          :src="observationDetailContent?.image || observationImage"
          alt="Observation Tool details Image"
          class="max-w-full h-auto rounded-lg"
        />
      </div>
      <div class="flex justify-end mt-12 mb-12">
        <!-- <SlButton
          class="buttonbackgroundColor h-8 mt-5 w-28 focus:outline-none btn_text text-white text-sm"
          :custom-width="'8rem'"
          @click="previousContent"
        >
        Tilbake
        </SlButton> -->
        <!-- This button should be to the right, so margin right should be like 5-->
        <SlButton
          class="buttonbackgroundColor h-8 mt-5 w-28 focus:outline-none btn_text text-white text-sm"
          :custom-width="'8rem'"
          @click="nextContent"
        >
          Neste
        </SlButton>
      </div>
    </div>
  </div>
  <ObservationPlanItem
    v-else-if="showPlan"
    :mode="mode"
    @close-popup="closeDetails"
    @switch-component="handleSwitchComponent"
  />
  <ObservationTheory
    v-else-if="showTheory"
    :mode="mode"
    @close-popup="closeDetails"
    @switch-component="handleSwitchComponent"
  />
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted, ref } from 'vue'
  import { useCMSStore } from '@/store/useCMSStore'
  import { useUserStore } from '@/store/useUserStore'
  import observationImage from '@/assets/observation.svg'
  import SlButton from '@/components/base/SlButton.vue'
  import ObservationPlanItem from '@/components/ObservationPlanItem.vue'
  import ObservationTheory from '@/components/ObservationDetails.vue'
  export default defineComponent({
    name: 'ObservationToolDetails',
    components: {
      SlButton,
      ObservationPlanItem,
      ObservationTheory,
    },
    props: {
      mode: { type: String, required: true },
      title: { type: Object, required: false },
      class: { type: String, required: false },
    },
    emits: ['close-popup', 'switch-component'],
    setup(props, { emit }) {
      const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
      const { actions: userActions } = useUserStore()

      onMounted(() => {
        cmsActions.getObservationDetailContents()
      })

      const showTheory = ref(false)
      const observationDetailContent = computed(() => cmsGetters.observationDetail.value)

      const closeDetails = () => {
        emit('close-popup')
      }

      const handleObservationClick = () => {
        showTheory.value = true
        showPlan.value = false
      }

      const handleSwitchComponent = (component: string) => {
        if (component === 'detail') {
          showPlan.value = false
          showTheory.value = false
        }
      }

      const showPlan = ref(false)

      const nextContent = async () => {
        await cmsActions.getObservationPlanContents()
        if (cmsGetters.observationPlan.value) {
          showPlan.value = true
        }
      }

      return {
        observationDetailContent,
        closeDetails,
        handleSwitchComponent,
        observationImage,
        SlButton,
        nextContent,
        showPlan,
        handleObservationClick,
        showTheory,
      }
    },
  })
</script>

<style scoped>
  .fontLight {
    letter-spacing: 0px;
    font-weight: 300;
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }

  .font {
    letter-spacing: 0px;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
  }

  .btn_text {
    font-family: 'Inter-Medium', 'Inter', sans-serif;
  }

  .buttonbackgroundColor {
    background-color: var(--Canvas_Bluelight, #1a6899);
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
</style>

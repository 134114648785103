<template>
  <div class="">
    <div class="flex text-xl font-light leading-relaxed text-left item">
      <div
        v-if="item.headerAudio.nb_NO[0]"
        class="h-12 w-12 px-4 hover:bg-green-500 rounded-full flex items-center justify-center"
        :class="item.headerAudio.nb_NO[0] && playing == true ? ' bg-green-500' : ''"
      >
        <img
          class="h-10 lg:mx-2 cursor-pointer"
          :src="playing == false ? playButtons[0] : playButtons[1]"
          @click="playHeader(item.headerAudio.nb_NO[0].url)"
        />
      </div>
      {{ item.header }}
    </div>

    <audio id="headerAudioElement" ref="headerAudioElement" class="mt-2">
      <source
        v-if="item.headerAudio && item.headerAudio.nb_NO.length != 1"
        :src="item.headerAudio.nb_NO[0].url"
        type="audio/mp3"
      />
      Your browser does not support the audio tag.
    </audio>

    <div class="grid grid-cols-1 space-x-4 mt-4">
      <div class="justify-self-center">
        <img
          v-if="item.image && item.image.nb_NO && item.image.nb_NO.length == 1"
          v-cache
          :src="item.image.nb_NO[0].url"
          class="rounded-lg h-96"
        />
      </div>
      <div v-if="item.options.nb_NO.length != 0" class="col-span-2 font-light text-left">
        <p class="font-light text-left my-5">{{ item.question }}</p>

        <div v-for="(option, index) in item.options.nb_NO" :key="index">
          <div
            class="flex items-center space-4 my-2 p-4 bg-white hover:bg-green-200 rounded-lg"
            :class="option.type === tracking.details[item.indexID] ? 'bg-green-400' : ''"
            @click="
              ;(showWarning = option.type ? !userProgress.has(option.type + ':' + item.indexID) : false),
                option.next[0] ? updateNextId(option.next[0].id, option.type) : updateNextId('', option.type)
            "
          >
            <audio id="audioElement" ref="audioElement">
              <source v-if="option.audioFile[0]" :src="option.audioFile[0].url" type="audio/mpeg" />
              Your browser does not support the audio tag.
            </audio>

            <div
              v-if="option.audioFile[0]"
              class="h-12 w-12 px-4 hover:bg-green-500 rounded-full flex items-center justify-center"
              :class="option.next[0] && playing == true && playIndex == option.next[0].id ? ' bg-green-500' : ''"
            >
              <img
                class="h-8 lg:mx-2 cursor-pointer"
                :src="playing == false ? playButtons[0] : playButtons[1]"
                @click="playAudio(item.indexID, option.type, option.audioFile[0].url)"
              />
            </div>

            <div class="">
              {{ option.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="showWarning" class="mb-20 grid justify-items-center">
      <div v-if="showWarning" class="flex bg-white rounded-3xl m-4 p-2 space-x-4">
        <div class="h-8 w-8 rounded-full flex items-center justify-center">
          <img class="h-4" src="@/assets/Warning.svg" />
        </div>
        <p class="font-light text-base leading-relaxed text-red-600">Hør på lyden før du velger</p>
      </div>
      <div
        v-if="showNext && playing == false"
        class="w-1/6 cursor-pointer flex items-center justify-center bg-green-400 rounded-3xl m-4 p-2 space-x-4"
        @click="nextItem()"
      >
        <p v-if="!showResult" class="font-light text-base leading-relaxed text-white">Neste</p>
        <p v-else class="font-light text-base leading-relaxed text-white">Get results</p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent, PropType, ref, computed, onMounted } from 'vue'
  import { Item } from '@/types/itemModel'

  import { useUserStore } from '@/store/useUserStore'
  import { SimulatorResult } from '@/types/resultModel'
  import Audio from '@/assets/Audio.svg'
  import AudioON from '@/assets/AudioON.svg'
  const playButtons = [Audio, AudioON]
  export default defineComponent({
    name: 'SimulatorQuestion',
    props: {
      item: { type: Object as PropType<Item>, required: true },
    },
    setup(props, context) {
      const { getters: userGetters, actions: userActions } = useUserStore()
      const tracking = userGetters.selectedTracking.value as SimulatorResult
      const userProgress = userGetters.selectedUser.value.progress.records

      // Dynamic variables
      const showWarning = ref(false)
      const playing = ref(false)
      const playIndex = ref('')
      const showResult = ref(false)

      // Methods
      const updateNextId = (Id: string, optionType: string) => {
        console.log('nextID', Id)
        if (showWarning.value == false) {
          tracking.details[props.item.indexID] = optionType
          context.emit('update-nextid', Id)
          if ((Id == '' && tracking.details[props.item.indexID] == '') || props.item.options.nb_NO.length == 0) {
            showResult.value = true
          } else showResult.value = false
        }
      }

      const nextItem = () => {
        if (showResult.value) context.emit('result')
        else context.emit('next-item', 'forward')
      }
      const playHeader = (url: string) => {
        const audioDOMElement = document.getElementById('headerAudioElement')
        if (audioDOMElement instanceof HTMLAudioElement) {
          audioDOMElement.src = url
          // Temporarily commented playing of audio files
          audioDOMElement.play()
          playing.value = true
          audioDOMElement.addEventListener('ended', () => {
            playing.value = false
          })
        }
      }

      const playAudio = (indexId: string, optionType: string, playId: string) => {
        if (playing.value == false) {
          const audioDOMElement = document.getElementById('audioElement')

          if (audioDOMElement instanceof HTMLAudioElement) {
            audioDOMElement.src = playId
            audioDOMElement.play()
            playing.value = true
            playIndex.value = playId
            audioDOMElement.addEventListener('ended', () => {
              playing.value = false
              playIndex.value = ''
            })
          }
          if (optionType) {
            // To store if the audio file has been clicked at once
            userActions.completeProgressForItem(optionType, indexId)
            userActions.syncUser(false)
          }
        }
      }

      onMounted(() => {
        // if ((props.item.indexID.indexOf('\\u002e') == -1 || props.item.options.nb_NO.length == 1) && props.item.headerAudio.nb_NO.length == 1)
        if (props.item.headerAudio.nb_NO && props.item.headerAudio.nb_NO.length == 1)
          playHeader(props.item.headerAudio.nb_NO[0].url)
      })
      const scroll = () => {
        if (showWarning.value || showNext?.value) {
          const ele = document.getElementById('showWarning')
          if (ele !== null) ele.scrollIntoView()
        }
      }
      const showNext = computed(() => {
        scroll()
        return props.item.options.nb_NO.find((item) => item.type == tracking.details[props.item.indexID]) ? true : false
      })

      return {
        tracking,
        userProgress,
        playing: computed(() => playing.value),
        playIndex: computed(() => playIndex.value),
        playButtons,
        showWarning,
        showResult,
        showNext,
        //Methods
        updateNextId,
        playAudio,
        // addProgress,
        nextItem,
        playHeader,
      }
    },
  })
</script>
<style scoped>
  .item {
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }
</style>

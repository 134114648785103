/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */

const theHost = import.meta.env.VITE_SERVER_HOST as string
const thePort = import.meta.env.VITE_SERVER_PORT as string
let baseUrl = `${theHost}`

// Local development hosting requires the port for "hot reload" and for mobile app to server access over LAN
if (
  process.env.NODE_ENV === 'development' &&
  theHost &&
  (theHost.includes('localhost') || theHost.includes('10.0.0.') || theHost.includes('192.168.'))
) {
  baseUrl = `${theHost}:${thePort}`
}
console.log(baseUrl, 'url')

const assetRoot: string = import.meta.env.VITE_CMS_HOST + '/api/assets' || ''

// CMS
const cmsUrl: string = (import.meta.env.VITE_CMS_HOST as string) || ''
const cmsTokenUrl: string = (import.meta.env.VITE_SQUIDEX_TOKEN_URL as string) || ''

const consentStatesString = (import.meta.env.VITE_CONSENT_STATES as string) || ''
const consentStates = consentStatesString.split(',') || ''
const projectNamesString = (import.meta.env.VITE_PROJECT_NAMES as string) || ''
const projectNames = projectNamesString.split(',') || ''
const projectTypesString = (import.meta.env.VITE_PROJECT_TYPES as string) || ''
const projectTypes = projectTypesString.split(',') || ''

const nettskjemaUrl = import.meta.env.VITE_NETTSKJEMA_URL || ''

const masteryBaselineID = 'mastery-baseline'
let appVersion = document.documentElement.getAttribute('data-appversion') || ''

// The web-based app will always be the latest version, set the version directly from .env
// If not built with Cordova, 'data-appversion' will === '%%VERSION%%'
if (appVersion === '%%VERSION%%') appVersion = (import.meta.env.VITE_APP_VERSION as string) || ''

enum CordovaPathName {
  root = '.',
  users = 'users',
  participants = 'participants',
  games = 'games',
}
const deviceType: string = window.location.protocol == 'file:' ? 'mobile' : 'web'

const cordovaConstants = {
  videoRecordingMaxDuration: 300000, // 5 minutes
  audioRecordingMaxDuration: 300000, // 5 minutes
}

const taskColours = ['#A861A6', '#F84016', '#009F4B', '#A9BD50', '#FFDE01']

// User roles determine what is displayed on screen UI
// FIXME: Edit this enum to match the content of the env file
enum USER_ROLE {
  user = 'user',
  monitor = 'monitor',
  admin = 'admin',
  logs = 'logs',
}
// // Ensure enums match those defined in env file
// const t = Object.values(USER_ROLE)
// if (!userRoles.every((e: string) => t.indexOf(e as USER_ROLE) > -1) || userRoles.length !== t.length) {
//   console.error('USER_ROLE enum mismatch', { t, userRoles })
// }

// Actual names of projects created in Squidex
// FIXME: Edit this enum to match the content of the env file
enum PROJECT_NAME {
  videotool = 'videotool',
  guides = 'guides',
  survey = 'survey',
  simulator = 'v4v',
  observationtool = 'observationtool',
  none = 'none',
}
// Ensure enums match those defined in env file
const pn = Object.keys(PROJECT_NAME)
if (!projectNames.every((e: string) => pn.indexOf(e as PROJECT_NAME) > -1) || projectNames.length !== pn.length) {
  console.error('PROJECT_NAME enum mismatch', { enums: pn, env: projectNames })
}

// Determines the templates used to display this project
// FIXME: Edit this enum to match the content of the env file
enum PROJECT_TYPE {
  videotool = 'videotool',
  guides = 'guides',
  survey = 'survey',
  simulator = 'v4v',
  observationtool = 'observationtool',
  none = 'none',
}

export enum GUIDES {
  'Skolekultur, ledelse og samarbeid' = 'graduation',
  'Nyutdannedes kompetanse i profesjonsfellesskapet' = 'Competance',
  'Styringsdokumenter om profesjonsfellesskapet og veileders rolle' = 'ManagementDocumentProfessional1',
  'Styringsdokumenter om nyutdannede som ressurs' = 'ManagementDocumentNewGraduate1',
}
// Ensure enums match those defined in env file
const p = Object.keys(PROJECT_TYPE)
if (!projectTypes.every((e: string) => p.indexOf(e as PROJECT_TYPE) > -1) || projectTypes.length !== p.length) {
  console.error('PROJECT_TYPE enum mismatch', { enums: p, env: projectTypes })
}

enum LanguageCodes {
  iv = 'iv', // Invariant code

  no = 'no',
  en = 'en',
}
enum LanguageNames {
  ar = 'العربية', // Arabic
  bs = 'bosanski', // Bosnian
  nb_NO = 'Norsk Bokmål',
  no = 'Norsk',
  /*  nn_NO = 'Norsk Nynorsk', */
  en = 'English',
  ku = 'Soranî', // Kurdish
  pl = 'Polski', // Polish
  so = 'af Soomaali', // Somali
  /*   sq = 'shqip', // Albanian */
  ta = 'தமிழ்', // Tamil
  tr = 'Türkçe',
  ur = 'اردو', // Urdu
  /*  vi = 'tiếng việt', // Vietnamese */
}
enum BrowserLanguageCodes {
  system = 'system', // System default
  ar = 'ar',
  bs = 'bs',
  'nb-NO' = 'nb-NO',
  'nn-NO' = 'nn-NO',
  nb_NO = 'nb_NO',
  no = 'nb_NO',
  en = 'en',
  ku = 'ku',
  pl = 'pl',
  so = 'so',
  ta = 'ta',
  tr = 'tr',
  ur = 'ur',
  /*  sq = 'sq', */
  /*   vi
   = 'vi', */
}
interface LF {
  [key: string]: Array<BrowserLanguageCodes>
}
const LanguageFallbacks: LF = {
  en: [],
  'nn-NO': [BrowserLanguageCodes['nb-NO'], BrowserLanguageCodes.en],
  'nb-NO': [BrowserLanguageCodes.en],
  nb_NO: [BrowserLanguageCodes['nb-NO'], BrowserLanguageCodes.en],
}
const BLCToSLC: Record<BrowserLanguageCodes, string> = {
  system: 'system', // System default
  ar: 'ar',
  bs: 'bs',
  'nb-NO': 'nb_NO',
  'nn-NO': 'nn_NO',
  nb_NO: 'nb_NO',
  en: 'en',
  ku: 'ku',
  pl: 'pl',
  so: 'so',
  /*  sq = 'sq', */
  ta: 'ta',
  tr: 'tr',
  ur: 'ur',
  /*   vi = 'vi', */
}

// Squidex stores language codes using underscore '_' rather than hyphen '-' :-/
export enum SquidexLanguageCodes {
  iv = 'iv', // Invariant code
  ar = 'ar',
  bs = 'bs',
  'nb-NO' = 'nb_NO',
  'nn-NO' = 'nn_NO',
  en = 'en',
  ku = 'ku',
  pl = 'pl',
  so = 'so',
  /*  sq = 'sq', */
  ta = 'ta',
  tr = 'tr',
  ur = 'ur',
  /*   vi = 'vi', */
}

export {
  deviceType,
  baseUrl,
  cmsUrl,
  cmsTokenUrl,
  // cmsClientId,
  // cmsClientSecret,
  assetRoot,
  // userRoles,
  USER_ROLE,
  PROJECT_NAME,
  CordovaPathName,
  PROJECT_TYPE,
  consentStates,
  cordovaConstants,
  BrowserLanguageCodes,
  BLCToSLC,
  appVersion,
  masteryBaselineID,
  taskColours,
  LanguageCodes,
  LanguageNames,
  LanguageFallbacks,
  nettskjemaUrl,
}

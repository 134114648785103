import * as d from 'd3'
import * as scale from 'd3-scale'
let d3 = Object.assign({}, d, scale)

function wrap(text, width) {
  text.each(function () {
    var text = d3.select(this),
      words = text.text().split(/\s+/).reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.1, // ems
      y = text.attr('y'),
      dy = parseFloat(text.attr('dy')),
      tspan = text
        .text(null)
        .append('tspan')
        .attr('x', 0)
        .attr('y', y)
        .attr('dy', dy + 'em')
    while (word == words.pop()) {
      line.push(word)
      tspan.text(line.join(' '))
      if (tspan.node().getComputedTextLength() > width) {
        line.pop()
        tspan.text(line.join(' '))
        line = [word]
        tspan = text
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', ++lineNumber * lineHeight + dy + 'em')
          .text(word)
      }
    }
  })
}
const setupLollipop = (id, data) => {
  var margin = { top: 10, right: 30, bottom: 30, left: 420 },
    width = 960 - margin.left - margin.right,
    height = 500 - margin.top - margin.bottom

  // append the svg object to the body of the page
  var svg = d3
    .select(id)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

  // Add X axis
  var x = d3.scaleLinear().domain([1, 7]).range([0, width])

  // Y axis
  var y = d3
    .scaleBand()
    .range([0, height])
    .domain(
      data.map(function (d) {
        return d.group
      }),
    )
    .paddingInner(1)

  svg
    .append('g')
    .attr('transform', 'translate(0,' + height + ')')
    .call(d3.axisBottom(x))

  svg.append('g').call(d3.axisLeft(y)).selectAll('group').call(wrap)

  // Lines
  svg
    .selectAll('myline')
    .data(data)
    .enter()
    .append('line')
    .attr('x1', function (d) {
      return x(d.value1)
    })
    .attr('x2', function (d) {
      return x(d.value2)
    })
    .attr('y1', function (d) {
      return y(d.group)
    })
    .attr('y2', function (d) {
      return y(d.group)
    })
    .attr('stroke', 'grey')
    .attr('stroke-width', '1px')

  // Circles of variable 1
  svg
    .selectAll('mycircle')
    .data(data)
    .enter()
    .append('circle')
    .attr('class', 'legend')
    .attr('cx', function (d) {
      return x(d.value1)
    })
    .attr('cy', function (d) {
      return y(d.group)
    })
    .attr('r', '6')
    .style('fill', '#cc333f')
    .on('mouseover', function () {
      const legend = document.getElementsByClassName('legend0')
      legend[0].style.display = 'block'
    })
    .on('mouseout', function () {
      const legend = document.getElementsByClassName('legend0')
      legend[0].style.display = 'none'
    })

  // Circles of variable 2
  svg
    .selectAll('mycircle')
    .data(data)
    .enter()
    .append('circle')
    .attr('class', 'legend')
    .attr('cx', function (d) {
      return x(d.value2)
    })
    .attr('cy', function (d) {
      return y(d.group)
    })
    .attr('r', '6')
    .style('fill', '#00a0b0')
    .on('mouseover', function () {
      const legend = document.getElementsByClassName('legend1')
      legend[0].style.display = 'block'
    })
    .on('mouseout', function () {
      const legend = document.getElementsByClassName('legend1')
      legend[0].style.display = 'none'
    })
}
export { setupLollipop }

<template>
  <div
    class="grid rounded-lg m-6 border-2"
    :class="mode !== PROJECT_NAME.simulator ? ' border-cyan-700' : leadership + 'Border'"
  >
    <div class="relative">
      <p v-if="selectedLeadership" class="text-3xl mt-4">{{ selectedLeadership.header }}</p>
      <p class="cursor-pointer text-xl absolute top-1 right-4" @click="close">X</p>
    </div>
    <div class="flex flex-col justify-center m-4">
      <div class="flex flex-row justify-center">
        <div v-if="selectedLeadership && mode == 'v4v'" class="h-40 w-40 rounded-full">
          <img
            v-if="selectedLeadership.header"
            class="rounded-full"
            :src="`/images/${selectedLeadership.header}.svg`"
          />
        </div>

        <p
          v-if="selectedLeadership?.content"
          class="text-left text-lg font-light m-6"
          v-html="selectedLeadership.content"
        ></p>
      </div>
      <p>
        <span
          v-if="selectedLeadership?.introduction"
          class="justify-center text-lg font-light my-4 header"
          v-html="selectedLeadership.introduction"
        ></span>
      </p>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent, computed } from 'vue'
  import { useCMSStore } from '../store/useCMSStore'
  import { LEADERSHIP_TYPES } from '@/types/itemModel'
  import { PROJECT_NAME } from '@/constants'

  export default defineComponent({
    name: 'LeadershipComponent',
    props: {
      leadership: { type: String, required: true },
      mode: { type: String, required: true },
    },
    emits: ['close-popup'],
    setup(prop, context) {
      const { getters: cmsGetters } = useCMSStore()

      function close() {
        context.emit('close-popup')
      }
      return {
        selectedLeadership: computed(() => {
          return cmsGetters.leaderships.value.get(prop.leadership)
        }),
        // getImage,
        LEADERSHIP_TYPES,
        PROJECT_NAME,
        close,
      }
    },
  })
</script>
<style scoped>
  .header {
    font-family: 'Inter', sans-serif;
  }
  .DemokratiskBorder {
    border-color: #fd8204;
  }
  .AutoritativBorder {
    border-color: #feef35;
  }
  .AutoritærBorder {
    border-color: #144578;
  }
  .EttergivendeBorder {
    border-color: #05d394;
  }
</style>

<template>
  <div>
    <div v-if="!showForm" class="grid grid-rows-1 justify-items-center">
      <div class="lg:w-5/6 xl:w-3/6 bg-white rounded-lg -mt-6">
        <div class="flex justify-between">
          <h1 class="text-3xl fontLight mb-6 text-center w-full">{{ observationPlan?.title }}</h1>
          <span class="cursor-pointer text-xl" @click="closeDetails">X</span>
        </div>

        <div class="mb-8">
          <p class="text-base font text-center w-full" v-html="observationPlan?.content"></p>
        </div>

        <!-- div for image -->
        <div class="my-24">
          <img :src="observationPlanImage" alt="Observation Plan Image" class="max-w-full h-auto rounded-lg" />
        </div>

        <!-- 7cs section -->
        <div v-if="dimensions">
          <h1 class="text-3xl fontLight mb-12 mt-12 text-center">{{ dimensions?.title }}</h1>
          <div v-for="(tab, index) in dimensions?.tabs" :key="index" class="mb-4">
            <div
              @click="toggleTab(index)"
              class="flex justify-between items-center p-4 bg-white rounded-lg cursor-pointer hover:bg-gray-50 transition-colors shadow-sm border border-b-2"
            >
              <h2 class="text-xl font-medium">{{ tab.heading }}</h2>
              <span
                class="transform transition-transform duration-300"
                :class="activeTab.includes(index) ? 'rotate-180' : ''"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M7 10L12 15L17 10"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <transition name="collapse">
              <div
                v-show="activeTab.includes(index)"
                class="bg-white p-6 rounded-b-lg shadow-sm mt-1 border-t-0 border border-b-2"
              >
                <p class="text-base font" v-html="tab.content"></p>
              </div>
            </transition>
          </div>
        </div>

        <div class="flex justify-between mt-12 mb-12">
          <SlButton
            class="buttonbackgroundColor h-8 mt-5 w-28 focus:outline-none btn_text text-white text-sm"
            :custom-width="'8rem'"
            @click="previousContent"
          >
            Tilbake
          </SlButton>
          <SlButton
            class="buttonbackgroundColor h-8 mt-5 w-28 focus:outline-none btn_text text-white text-sm"
            :custom-width="'8rem'"
            @click="nextContent"
          >
            Neste
          </SlButton>
        </div>
      </div>
    </div>

    <ObservationFormItem v-else :mode="mode" @close-popup="closeDetails" @switch-component="handleSwitchComponent" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted, ref } from 'vue'
  import { useCMSStore } from '@/store/useCMSStore'
  import { useUserStore } from '@/store/useUserStore'
  import observationPlanImage from '@/assets/observationplan.png'
  import SlButton from '@/components/base/SlButton.vue'
  import ObservationFormItem from './ObservationFormItem.vue'

  export default defineComponent({
    name: 'ObservationPlanItem',
    components: {
      SlButton,
      ObservationFormItem,
    },
    props: {
      mode: { type: String, required: true },
    },
    emits: ['close-popup', 'switch-component', 'switch-component-form'],
    setup(props, { emit }) {
      const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
      const { actions: userActions } = useUserStore()
      const observationPlan = computed(() => cmsGetters.observationPlan.value)
      const dimensions = computed(() => cmsGetters.dimensions.value)

      onMounted(() => {
        cmsActions.getDimensionsContents()
      })

      const showForm = ref(false)
      const closeDetails = () => {
        emit('close-popup')
      }

      const handleSwitchComponent = (component: string) => {
        if (component === 'plan') {
          showForm.value = false
        }
      }

      const previousContent = () => {
        emit('switch-component', 'detail')
      }

      const nextContent = () => {
        showForm.value = true
      }

      const activeTab = ref<number[]>([])
      const toggleTab = (index: number) => {
        const currentIndex = activeTab.value.indexOf(index)
        if (currentIndex === -1) {
          activeTab.value.push(index)
        } else {
          activeTab.value.splice(currentIndex, 1)
        }
      }

      const showPlan = ref(false)

      return {
        observationPlan,
        closeDetails,
        observationPlanImage,
        SlButton,
        previousContent,
        nextContent,
        dimensions,
        activeTab,
        toggleTab,
        handleSwitchComponent,
        showPlan,
        showForm,
      }
    },
  })
</script>

<style scoped>
  .fontLight {
    letter-spacing: 0px;
    font-weight: 300;
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }

  .font {
    letter-spacing: 0px;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
  }

  .btn_text {
    font-family: 'Inter-Medium', 'Inter', sans-serif;
  }

  .buttonbackgroundColor {
    background-color: var(--Canvas_Bluelight, #1a6899);
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .collapse-enter-active,
  .collapse-leave-active {
    transition: all 0.3s ease-in-out;
    max-height: 300px;
    opacity: 1;
  }

  .collapse-enter-from,
  .collapse-leave-to {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }

  .dimension-content {
    background-color: white;
    border-radius: 0 0 0.5rem 0.5rem;
  }
</style>

import './tailwind.css'
import './assets/main.css'
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { globalTranslations } from '@/translations'
import router from './router'
import App from './App.vue'

import { useAppStore } from './store/useAppStore'
import useDeviceService from './composition/useDevice'

const { actions: deviceActions } = useDeviceService()
const { actions: appActions } = useAppStore()

const app = createApp(App)

const i18n = createI18n({
  locale: navigator.language || 'nb-NO',
  fallbackLocale: {
    'nb-NO': ['nb-NO', 'en'],
    nb: ['nb-NO', 'en'],
    'nn-NO': ['nn-NO', 'nb-NO', 'en'],
    nn: ['nn-NO', 'nb-NO', 'en'],
    'en-AU': ['en'],
  },
  legacy: false,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  messages: globalTranslations,
})

// Redirected OAuth login for mobile devices
// Using cordova-plugin-oauth
// Called from a callback URL like
// com.example.foo://oauth_callback?code=b10a8db164e0754105b7a99be72e3fe5
// it would be received in JavaScript like this:
window.addEventListener('message', function (event) {
  if (typeof event.data === 'string' && event.data.match(/^oauth::/)) {
    const data = JSON.parse(event.data.substring(7))
    alert('jiokol')
    if (data.mode == 'login' && data.code && data.code !== 'undefined') {
      // The JWT will be sent with future requests to authenticate Mobile users in case the session has expired
      localStorage.setItem('jwt', data.code)
      appActions.tokenLogin()
    } else {
      router.push('/')
    }
  }
})

/* interface SLPlusCustomEventDetail {
  type: string
  error: Error
} */
// Catch slPLus errors sent via utilities.js error function
window.addEventListener('slpluserror', ((event: CustomEvent) => {
  //do something
  const error = event.detail
  if (error) {
    const errorText = error.toString()
    console.log(errorText)
    deviceActions.logErrorMessage(errorText)
  }
}) as EventListener)

// Catch unhandled errors
window.addEventListener('unhandledrejection', function (event) {
  console.warn(`Uncaught promise: ${event.toString()} Reason: ${event.toString()}`)
})

// Media cache Directive
// Use this to cache(save and load) CMS-based images & videos to the mobile device
// e.g:  <img src="..." v-cache>
// NO NEED to use this with local assets
app.directive('cache', (el: HTMLImageElement) => {
  deviceActions.getCachedMedia(el.src).then((source) => {
    el.src = source as string
  })
})

// Bootstrap the Vue app when called
const initialiseApp = () => {
  app.use(router).use(i18n).mount('#app')
}

initialiseApp()

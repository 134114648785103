<template>
  <div class="relative">
    <div class="inline-block w-64 absolute z-50">
      <div class="relative">
        <select
          v-model="chartType"
          class="block w-full px-4 py-2.5 bg-white rounded-lg border border-gray-200 hover:border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-sm font-medium text-gray-700 appearance-none transition-all duration-200 shadow-sm"
          @change="chooseChart"
        >
          <option value="spider-chart">Spider Chart</option>
          <option value="lollipop-chart">Lollipop Chart</option>
        </select>

        <!-- Modern chevron icon -->
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400">
          <svg
            class="w-4 h-4 transition-transform duration-200"
            :class="{ 'rotate-180': isOpen }"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </div>
    </div>
    <!-- Graph view -->
    <div class="h-screen">
      <RadarGraph v-if="chartType === 'spider-chart'"></RadarGraph>
      <LollipopGraph v-if="chartType === 'lollipop-chart'"></LollipopGraph>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import RadarGraph from '@/components/RadarGraphComponent.vue'
  import LollipopGraph from '@/components/LollipopGraphComponent.vue'

  export default defineComponent({
    components: {
      RadarGraph,
      LollipopGraph,
    },
    setup() {
      const chartType = ref('spider-chart')
      const isOpen = ref(false)

      const chooseChart = () => {
        isOpen.value = false
      }
      return {
        chooseChart,
        chartType,
        isOpen,
      }
    },
  })
</script>

<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: none;
  }

  select option {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }

  select:focus {
    outline: none;
  }
</style>

export interface ObservationData {
  title: { nb_NO: string }
  subtitle: { nb_NO: string }
  tabs: {
    iv: Array<{
      heading: string
      subHeading: string
      content: string
      image?: string
    }>
  }
  notes: { nb_NO: string }
}

export class Observation {
  title: string
  subtitle: string
  tabs: Array<{
    heading: string
    subHeading: string
    content: string
    image?: string
  }>
  notes: string
  id: string

  constructor(data: ObservationData, id: string) {
    this.title = data.title.nb_NO
    this.subtitle = data.subtitle.nb_NO
    this.tabs = data.tabs.iv
    this.notes = data.notes.nb_NO
    this.id = id
  }
}

export interface ObservationTheoryData {
  title: { iv: string }
  introduction: { iv: string }
  tabs: {
    iv: Array<{
      heading: string
      content: string
    }>
  }
  notes: { iv: string }
}

export class ObservationTheory {
  title: string
  introduction: string
  tabs: Array<{
    heading: string
    content: string
  }>
  notes: string
  id: string

  constructor(data: ObservationTheoryData, id: string) {
    this.title = data.title.iv
    this.introduction = data.introduction.iv
    this.tabs = data.tabs.iv
    this.notes = data.notes.iv
    this.id = id
  }
}

export interface ObservationDetailData {
  title: { iv: string }
  content: { iv: string }
  image: { iv: { id: string; url: string; mimeType: string; fileName: string } }
}

export class ObservationDetail {
  title: string
  content: string
  image: string
  id: string

  constructor(data: ObservationDetailData, id: string) {
    this.title = data.title.iv
    this.content = data.content.iv
    this.image = data.image.iv.url
    this.id = id
  }
}

export interface DimensionsData {
  title: { iv: string }
  tabs: {
    iv: Array<{ heading: string; content: string }>
  }
}

export class Dimensions {
  title: string
  tabs: Array<{ heading: string; content: string }>
  id: string

  constructor(data: DimensionsData, id: string) {
    this.title = data.title.iv
    this.tabs = data.tabs.iv
    this.id = id
  }
}

export interface ObservationPlanData {
  title: { iv: string }
  content: { iv: string }
}

export class ObservationPlan {
  title: string
  content: string
  id: string

  constructor(data: ObservationPlanData, id: string) {
    this.title = data.title.iv
    this.content = data.content.iv
    this.id = id
  }
}

export interface ObservationFormData {
  heading: { iv: string }
  title: { iv: string }
  description: { iv: string }
  formfields: { iv: Array<{ field: string; placeholder: string; maxlength: number }> }
  buttontext: { iv: string }
}

export class ObservationForm {
  heading: string
  title: string
  description: string
  formfields: Array<{ field: string; placeholder: string; maxlength: number }>
  buttontext: string
  id: string

  constructor(data: ObservationFormData, id: string) {
    this.heading = data.heading.iv
    this.title = data.title.iv
    this.description = data.description.iv
    this.formfields = data.formfields.iv
    this.buttontext = data.buttontext.iv
    this.id = id
  }
}

export interface ObservationLearnData {
  title: { iv: string }
  content: { iv: string }
}

export class ObservationLearn {
  title: string
  content: string
  id: string

  constructor(data: ObservationLearnData, id: string) {
    this.title = data.title.iv
    this.content = data.content.iv
    this.id = id
  }
}

export interface IndividualReflexData {
  title: { iv: string }
  content: { iv: string }
}

export class IndividualReflex {
  title: string
  content: string
  id: string

  constructor(data: IndividualReflexData, id: string) {
    this.title = data.title.iv
    this.content = data.content.iv
    this.id = id
  }
}

export interface GuidanceData {
  title: { iv: string }
  content: { iv: string }
}

export class Guidance {
  title: string
  content: string
  id: string

  constructor(data: GuidanceData, id: string) {
    this.title = data.title.iv
    this.content = data.content.iv
    this.id = id
  }
}

<template>
  <div class="grid grid-rows-1 justify-items-center fontLight mx-80">
    <!-- header -->
    <div class="grid grid-rows-1 justify-items-center text-center">
      <p class="light text-4xl font-light leading-relaxed">Velkommen til beslutningssimulatoren</p>
      <p class="light text-lg leading-normal font-light w-3/6 mt-1">Simulatoren består av tre ulike elementer:</p>
    </div>

    <div class="grid grid-cols-3 justify-center space-x-4 mx-32 w-5/6">
      <div class="flex flex-col bg-white rounded-3xl h-full">
        <div style="background-color: #ebc870" class="h-48 flex items-center justify-center rounded-t-3xl">
          <img class="mx-12 h-28 -mt-4" src="@/assets/Teacher.svg" />
        </div>

        <div class="flex-1 rounded-b-3xl p-4" style="background-color: #f5f5f7">
          <p class="text text-red-500 text-base leading-normal">Situasjoner i klasserommet</p>
          <p class="para leading-relaxed font-light text-base mt-2">
            Du blir presentert for ulike situasjoner som kan oppstå i klasserommet. Hva gjør du? Velg det alternativet
            som passer best for deg.
          </p>
        </div>
      </div>
      <div class="flex flex-col bg-white rounded-3xl h-full">
        <div style="background-color: #ffc98f" class="h-48 flex items-center justify-center rounded-t-3xl">
          <img class="mx-12 h-20" src="@/assets/Audio1.svg" />
        </div>

        <div class="flex-1 rounded-b-3xl p-4" style="background-color: #f5f5f7">
          <p class="text text-red-500 text-base leading-normal">Lydfiler</p>
          <p class="para leading-relaxed font-light text-base mt-2">
            Trykk på ikonene for lyd for å høre hvordan lærer og elever snakker.
          </p>
        </div>
      </div>
      <div class="flex flex-col bg-white rounded-3xl h-full">
        <div style="background-color: #438bea" class="h-48 flex items-center justify-center rounded-t-3xl">
          <img class="mx-12 h-20" src="@/assets/Calendar.svg" />
        </div>

        <div class="flex-1 rounded-b-3xl p-4" style="background-color: #f5f5f7">
          <p class="text text-red-500 text-base leading-normal">Resultater</p>
          <p class="para leading-relaxed font-light text-base mt-2">
            Når du har fullført simulatoren blir du presentert for hvilke tilnærminger til lærerrollen som
            karakteriserer dine valg i simulatoren. Resultatene kan du bruke til refleksjon og diskusjon om lærerrolle
            med veileder og medstudenter.
          </p>
        </div>
      </div>
    </div>
    <div>
      <SlButton class="mt-2 justify-self-end w-36" @click="forward()">Start</SlButton>
    </div>
  </div>
</template>
<script lang="ts">
  import SlButton from '@/components/base/SlButton.vue'
  import { defineComponent } from 'vue'
  import { useUserStore } from '@/store/useUserStore'
  import router from '@/router'
  import { useCMSStore } from '../store/useCMSStore'
  import { SimulatorResult } from '@/types/resultModel'
  import { TRACKING_TYPE } from '@/types/main'
  import { PROJECT_TYPE } from '@/constants'

  export default defineComponent({
    name: 'SimulationTest',
    components: { SlButton },
    setup() {
      const { getters: cmsGetters } = useCMSStore()

      let result: SimulatorResult
      const items = cmsGetters.items
      const { getters: userGetters, actions: userActions } = useUserStore()
      const forward = () => {
        //Create placeholders for Tracking based on the items
        result = new SimulatorResult({
          itemID: userGetters.selectedUser.value._id,
          userID: userGetters.selectedUser.value._id,
          projectID: PROJECT_TYPE.simulator,
          type: PROJECT_TYPE.simulator,
        })
        items.value.forEach((i) => {
          if (i.indexID.includes('.')) {
            i.indexID = i.indexID.split('.').join('\\u002e')
          }
          Object.assign((result.details[i.indexID] = ''), result.details[i.indexID])
        })
        // commit new tracking in User Store
        userActions.commitNewTracking(result)
        router.push('/simulator/items')
      }
      return {
        //Methods
        forward,
      }
    },
  })
</script>
<style scoped>
  .text {
    font-family: 'Inter', sans-serif;
  }
  .light {
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }
  .para {
    color: var(--texte_grey_black_title, #0f0f0f);
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }
</style>

<template class="h-screen overflow-scroll">
  <div class="h-screen">
    <div class="grid grid-rows-1 justify-items-center" style="background-color: #f5f5f7">
      <div class="justify-self-center w-4/5">
        <div class="mt-20 flex flex-row space-x-2 justify-between" style="background-color: #f5f5f7">
          <div class="rounded-lg mr-64 h-2 w-2/6 bg-white">
            <div class="rounded-lg bg-blue-400 h-2" :style="`width:${progress}%`"></div>
          </div>
          <div class="flex flex-row space-x-4">
            <div
              v-if="selectedItem?.previousItem"
              class="cursor-pointer h-11 w-11 flex items-center justify-center bg-white rounded-full"
              @click="nextItem('backward')"
            >
              <img class="h-5" src="@/assets/ArrowLeft.svg" />
            </div>

            <div
              class="cursor-pointer h-11 w-11 flex items-center justify-center bg-white rounded-full"
              @click="cancel()"
            >
              <img class="h-5" src="@/assets/Cross.svg" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectedItem" class="justify-self-center w-3/6">
        <p class="text-3xl font-bold header text-left">
          Scenario:
          {{ selectedItem.indexID.split('\\u002e').join('.') }}
        </p>
        <!-- Item component -->
        <SimulationItem
          :key="selectedItem.indexID"
          class=""
          :item="selectedItem"
          @update-nextid="updateNextId"
          @next-item="nextItem"
          @result="result"
        ></SimulationItem>
        <!-- Forward button -->
        <div
          v-if="
            nextId &&
            selectedTracking &&
            selectedTracking.details[selectedItem.indexID] &&
            selectedItem.options.nb_NO.length != 0
          "
          class="flex justify-center"
        ></div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent, ref, computed } from 'vue'
  import { useCMSStore } from '@/store/useCMSStore'
  // import { useAppStore } from '@/store/useAppStore'
  import router from '@/router'
  import SimulationItem from '@/components/SimulationItem.vue'
  import { useUserStore } from '@/store/useUserStore'
  // import { PROJECT_NAME } from '@/constants'
  export default defineComponent({
    name: 'SimultionItems',
    components: { /*SlButton*/ SimulationItem },
    setup() {
      const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
      // const { getters: appGetters, actions: appActions } = useAppStore()
      const nextId = ref('')

      const items = cmsGetters.items
      const selectedItem = computed(() => cmsGetters.selectedItem.value)
      const { getters: userGetters, actions: userActions } = useUserStore()

      /**----------- Methods---------------*/
      const nextItem = (mode: string) => {
        if (mode == 'forward' && nextId.value != '') {
          cmsActions.updatePreviousItem(nextId.value)
          cmsActions.selectItem(nextId.value)
        } else if (mode == 'backward') {
          if (selectedItem.value?.previousItem) cmsActions.selectItem(selectedItem.value.previousItem)
        }
      }

      const result = () => {
        userActions.updateTrackings().then(() => userActions.sendTrackings().then(() => userActions.clearTrackings()))
        router.push('/tool')
      }

      const updateNextId = (id: string) => {
        nextId.value = id
      }
      const cancel = () => {
        router.push('/tool')
      }

      return {
        items,
        selectedItem,
        nextId,
        progress: computed(() => (selectedItem.value?.indexID ? (parseInt(selectedItem.value.indexID) / 6) * 100 : 0)),
        selectedTracking: userGetters.selectedTracking,
        //Methods
        result,
        cancel,
        nextItem,
        updateNextId,
      }
    },
  })
</script>
<style scoped>
  .header {
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    letter-spacing: 0px;
    line-height: 1.6;
    font-family: 'Inter-Light', 'Inter', sans-serif;
    text-align: left;
    color: #000000;
    font-size: 18px;
  }
</style>
